import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UserService } from 'src/app/services/user/user.service';
import { Storage } from '@ionic/storage';
import { SelectAddressPage } from '../../select-address/select-address.page';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { ProductUtilsService } from 'src/app/services/product-utils/product-utils.service';
var BookingSchedulePage = /** @class */ (function () {
    function BookingSchedulePage(userService, bookingService, sharedService, labelService, router, configService, modalController, storage, paymentService, productUtilsService) {
        this.userService = userService;
        this.bookingService = bookingService;
        this.sharedService = sharedService;
        this.labelService = labelService;
        this.router = router;
        this.configService = configService;
        this.modalController = modalController;
        this.storage = storage;
        this.paymentService = paymentService;
        this.productUtilsService = productUtilsService;
        this.scheduleSlots = [];
        this.selectedTime = {};
    }
    BookingSchedulePage.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.getSchedules();
                        _a = this;
                        return [4 /*yield*/, this.storage.get('userDefaultAddress')];
                    case 1:
                        _a.address = _b.sent();
                        this.currencyCode = this.configService.environment.currencyCode;
                        this.userId = this.userService.getUserId();
                        this.taxType = this.configService.environment.taxType;
                        return [2 /*return*/];
                }
            });
        });
    };
    BookingSchedulePage.prototype.getSchedules = function () {
        if (this.data.scheduleData.active) {
            var maxDays = this.data.scheduleData.maxDays;
            var schedules = this.data.scheduleData.schedules;
            var _loop_1 = function (index) {
                var dayToCheck = moment().add(index, 'days');
                var schedule = schedules.filter(function (s) { return s.day === dayToCheck.format('dddd'); });
                if (schedule.length) {
                    if (schedule[0].active && schedule[0].schedule.length) {
                        this_1.scheduleSlots.push({
                            date: dayToCheck.toDate(),
                            schedules: schedule[0].schedule,
                            day: schedule[0].day,
                            active: false
                        });
                    }
                }
            };
            var this_1 = this;
            for (var index = 0; index < maxDays; index++) {
                _loop_1(index);
            }
            if (this.scheduleSlots.length) {
                this.scheduleSlots[0].active = true;
            }
        }
        console.log('this.scheduleSlots', this.scheduleSlots);
    };
    BookingSchedulePage.prototype.selectDate = function (i) {
        this.scheduleSlots[i].active = true;
        this.scheduleSlots.forEach(function (slot, index) {
            if (index !== i) {
                slot.active = false;
            }
        });
        this.selectedTime = {};
    };
    BookingSchedulePage.prototype.getActiveTimeSchedules = function () {
        return this.scheduleSlots.filter(function (s) { return s.active === true; })[0].schedules;
    };
    BookingSchedulePage.prototype.setTimeSlot = function (event) {
        this.selectedTime = event.target.value || {};
    };
    BookingSchedulePage.prototype.bookService = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var scheduleSlot, data, res, paymentModalObj;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.isCustomInputAvailable()) {
                            if (this.data.item.additionalInfo.customInput.required && !this.data.item.additionalInfo.customInput.value) {
                                this.sharedService.presentAlert(this.data.item.additionalInfo.customInput.label + " " + this.labelService.labels['BOOKING_SCHEDULE']['value_not_empty_alert']);
                                return [2 /*return*/];
                            }
                        }
                        return [4 /*yield*/, this.sharedService.presentLoading()];
                    case 1:
                        _a.sent();
                        scheduleSlot = this.scheduleSlots.filter(function (s) { return s.active === true; })[0];
                        data = {
                            item: tslib_1.__assign({}, this.data.item, { price: this.getTotalAmount().totalAmount, totalGst: this.getTotalAmount().totalGst }),
                            schedule: this.data.scheduleData.active ? {
                                active: true,
                                date: moment(scheduleSlot.date).format('DD-MM-YYYY'),
                                slot: {
                                    start: this.selectedTime.start,
                                    end: this.selectedTime.end
                                },
                                day: scheduleSlot.day
                            } : null,
                            vendor: this.data.vendor,
                            user: {
                                id: this.userService.getUserId(),
                                name: this.userService.getUserName(),
                                address: this.data.item.allowAddress && this.address ? this.address : null
                            },
                            status: this.isAllowPayment() ? 'pending' : 'accepted',
                            payment: {
                                required: this.isAllowPayment()
                            },
                            additionalInfo: this.data.item.additionalInfo
                        };
                        return [4 /*yield*/, this.bookingService.confirmBooking(data, this.selectedTime && this.selectedTime.slotLimit ? this.selectedTime.slotLimit : 0)];
                    case 2:
                        res = _a.sent();
                        this.sharedService.loading.dismiss();
                        if (res.status === 'added') {
                            this.modalController.dismiss();
                            if (this.isAllowPayment()) {
                                paymentModalObj = {
                                    order: {
                                        amount: this.getTotalAmount().totalAmount,
                                        id: res.id,
                                        collection: 'bookings',
                                        gst: data.item.gst,
                                        userId: this.userId
                                    },
                                    successRoute: 'user-booking'
                                };
                                this.openPaymentModal(paymentModalObj);
                            }
                            else {
                                this.sharedService.presentAlert(this.labelService.labels['BOOKING_SCHEDULE']['service_booked']);
                                this.router.navigate(['user-booking']);
                            }
                        }
                        else if (res.status === 'not_available') {
                            this.sharedService.presentAlert(this.labelService.labels['BOOKING_SCHEDULE']['slot_not_available']);
                        }
                        else {
                            this.sharedService.presentAlert(this.labelService.labels['SHARED']['some_issue_msg']);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BookingSchedulePage.prototype.getDate = function (date) {
        return moment(date).format('DD MMM');
    };
    BookingSchedulePage.prototype.isBookNowDisabled = function () {
        return this.data.scheduleData.active && !Object.keys(this.selectedTime).length;
    };
    BookingSchedulePage.prototype.isAllowPayment = function () {
        return this.data.item.allowPayment;
    };
    BookingSchedulePage.prototype.convert24to12 = function (time) {
        return this.sharedService.convert24to12(time);
    };
    BookingSchedulePage.prototype.onClickChangeOrAddAddress = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: SelectAddressPage,
                            cssClass: 'custom-modal',
                            componentProps: { pageType: 'modal' }
                        })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var _a;
                            return tslib_1.__generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        if (!(res && res.data && !res.data.closed)) return [3 /*break*/, 2];
                                        _a = this;
                                        return [4 /*yield*/, this.storage.get('userDefaultAddress')];
                                    case 1:
                                        _a.address = _b.sent();
                                        _b.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BookingSchedulePage.prototype.openPaymentModal = function (paymentModalObj) {
        this.paymentService.openPaymentModal(paymentModalObj);
    };
    BookingSchedulePage.prototype.getBookingDuration = function () {
        return this.productUtilsService.getBookingDuration(this.data);
    };
    BookingSchedulePage.prototype.getTotalAmount = function () {
        var totalGst = 0;
        var gstOnItem = 0;
        var gstOnAddons = 0;
        var totalAmount = this.data.item.price + this.data.item.addOns.totalPrice;
        if (this.data.item.gst.isExclusive) {
            gstOnItem = this.data.item.price * (this.data.item.gst.value / 100);
            gstOnAddons = this.data.item.addOns.totalPrice * (this.data.item.gst.value / 100);
            totalAmount += gstOnItem + gstOnAddons;
        }
        else {
            gstOnItem = this.data.item.price - (this.data.item.price / (1 + (this.data.item.gst.value / 100)));
            gstOnAddons = this.data.item.addOns.totalPrice - (this.data.item.addOns.totalPrice / (1 + (this.data.item.gst.value / 100)));
        }
        totalGst += gstOnItem + gstOnAddons;
        this.data.item.gst.total = totalGst;
        this.data.item.addOns.totalGst = gstOnAddons;
        return { totalGst: totalGst, totalAmount: totalAmount };
    };
    BookingSchedulePage.prototype.modalDismiss = function () {
        this.modalController.dismiss();
    };
    BookingSchedulePage.prototype.isCustomInputAvailable = function () {
        return this.data && this.data.item && this.data.item.additionalInfo && this.data.item.additionalInfo.customInput && this.data.item.additionalInfo.customInput.active;
    };
    return BookingSchedulePage;
}());
export { BookingSchedulePage };
