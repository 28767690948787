import * as tslib_1 from "tslib";
import { Storage } from '@ionic/storage';
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';
import * as firebase from 'firebase';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { convertSnaps } from '../db-utils';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@ionic/storage";
import * as i4 from "../loggly-logger/loggly-logger.service";
var DeliverySettingsService = /** @class */ (function () {
    function DeliverySettingsService(events, afs, storage, logglyService) {
        this.events = events;
        this.afs = afs;
        this.storage = storage;
        this.logglyService = logglyService;
        this.deliveryRef = this.afs.collection('features').doc('delivery');
    }
    DeliverySettingsService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('delivery-settings:saveDeliverySettings', function (data) {
            _this.saveDeliverySettings(data);
        });
        this.events.subscribe('delivery-settings:getDeliverySettingsData', function () {
            _this.getDeliverySettingsData();
        });
        this.events.subscribe('delivery-settings:getOrderPaymentDetails', function (address, products, isGstApplicable, customDeliverySettings, additionalInfo) {
            _this.getOrderPaymentDetails(address, products, isGstApplicable, customDeliverySettings, additionalInfo);
        });
        this.events.subscribe('delivery-settings:checkPincode', function (pincode) {
            _this.checkPincode(pincode);
        });
        this.events.subscribe('delivery-settings:getSlotsWithDate', function (date) {
            _this.getSlotsWithDate(date);
        });
        this.events.subscribe('delivery-settings:updateSlot', function (data) {
            _this.updateSlot(data);
        });
        this.events.subscribe('delivery-settings:getManageSlots', function () {
            _this.getManageSlots();
        });
    };
    DeliverySettingsService.prototype.saveDeliverySettings = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.deliveryRef.set(data)];
                    case 1:
                        _a.sent();
                        this.events.publish('delivery-settings:saveDeliverySettingsSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        error_1['location'] = 'delivery-settings-service:saveDeliverySettings';
                        this.logglyService.log(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DeliverySettingsService.prototype.getDeliverySettingsData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var deliveryData, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.deliveryRef.valueChanges().pipe(first()).toPromise()];
                    case 1:
                        deliveryData = _a.sent();
                        this.events.publish('delivery-settings:publishDeliverySettingsData', deliveryData);
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        error_2['location'] = 'delivery-settings-service:getDeliverySettingsData';
                        this.logglyService.log(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DeliverySettingsService.prototype.getDeliverySettingsDataInSessionStorage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var data;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    data = JSON.parse(sessionStorage.getItem("DeliverySettingsData")) || {};
                                    if (!!(Object.keys(data).length)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.deliveryRef.valueChanges().pipe(first()).toPromise()];
                                case 1:
                                    data = _a.sent();
                                    sessionStorage.setItem("DeliverySettingsData", JSON.stringify(data));
                                    _a.label = 2;
                                case 2:
                                    resolve(data);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    DeliverySettingsService.prototype.deliverySettingsDataInStorage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var deliveryData, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.deliveryRef.valueChanges().pipe(first()).toPromise()];
                    case 1:
                        deliveryData = _a.sent();
                        this.storage.set('deliverySettings', deliveryData);
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        console.dir(error_3);
                        error_3['location'] = 'delivery-settings-service:deliverySettingsDataInStorage';
                        this.logglyService.log(error_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DeliverySettingsService.prototype.getOrderPaymentDetails = function (address, products, isGstApplicable, customDeliverySettings, additionalInfo) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderPaymentDetails;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    console.log('customDeliverySettings', customDeliverySettings);
                    orderPaymentDetails = firebase.functions().httpsCallable('orders-getOrderPaymentDetails');
                    orderPaymentDetails({ address: address, products: products, isGstApplicable: isGstApplicable, customDeliverySettings: customDeliverySettings, additionalInfo: additionalInfo }).then(function (response) {
                        // console.log('response orderPaymentDetails', response.data);
                        _this.events.publish('delivery-settings:publishOrderPaymentDetails', response.data);
                    });
                }
                catch (error) {
                    console.dir(error);
                    error['location'] = 'delivery-settings-service:getOrderPaymentDetails';
                    this.logglyService.log(error);
                }
                return [2 /*return*/];
            });
        });
    };
    DeliverySettingsService.prototype.checkPincode = function (pincode) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var deliveryData, pincodes, isAvailable, _i, pincodes_1, p, error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.deliveryRef.valueChanges().pipe(first()).toPromise()];
                    case 1:
                        deliveryData = _a.sent();
                        pincodes = deliveryData.deliveryPincodes;
                        isAvailable = false;
                        for (_i = 0, pincodes_1 = pincodes; _i < pincodes_1.length; _i++) {
                            p = pincodes_1[_i];
                            if (p.pincode == pincode) {
                                isAvailable = true;
                                break;
                            }
                        }
                        if (deliveryData.isAllowAllPincodes) {
                            this.events.publish('delivery-settings:pincodeDeliverable');
                        }
                        else {
                            if (isAvailable) {
                                this.events.publish('delivery-settings:pincodeDeliverable');
                            }
                            else {
                                this.events.publish('delivery-settings:pincodeNotDeliverable');
                            }
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _a.sent();
                        console.dir(error_4);
                        error_4['location'] = 'delivery-settings-service:checkPincode';
                        this.logglyService.log(error_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DeliverySettingsService.prototype.getSlotsWithDate = function (date) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var slotsDoc, error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.deliveryRef.collection('manageSlots').doc(date).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        slotsDoc = _a.sent();
                        this.events.publish('delivery-settings:slotsWithDate', slotsDoc);
                        return [3 /*break*/, 3];
                    case 2:
                        error_5 = _a.sent();
                        console.dir(error_5);
                        error_5['location'] = 'delivery-settings-service:getSlotsWithDate';
                        this.logglyService.log(error_5);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DeliverySettingsService.prototype.updateSlot = function (slotData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var date, error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        date = slotData.date.toDateString();
                        return [4 /*yield*/, this.deliveryRef.collection('manageSlots').doc(date).set({ slots: slotData.slots })];
                    case 1:
                        _a.sent();
                        this.events.publish('delivery-settings:slotUpdated');
                        return [3 /*break*/, 3];
                    case 2:
                        error_6 = _a.sent();
                        console.dir(error_6);
                        error_6['location'] = 'delivery-settings-service:updateSlot';
                        this.logglyService.log(error_6);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DeliverySettingsService.prototype.getManageSlots = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var manageSlots, error_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.deliveryRef.collection('manageSlots').snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        manageSlots = _a.sent();
                        this.events.publish('delivery-settings:publishManageSlots', manageSlots);
                        return [3 /*break*/, 3];
                    case 2:
                        error_7 = _a.sent();
                        console.dir(error_7);
                        error_7['location'] = 'delivery-settings-service:getManageSlots';
                        this.logglyService.log(error_7);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DeliverySettingsService.prototype.getCustomDelivery = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var customDelivery;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.afs.collection('features').doc('delivery').collection('custom')
                                        .snapshotChanges()
                                        .pipe(map(function (snaps) { return convertSnaps(snaps); }))
                                        .pipe(first()).toPromise()];
                                case 1:
                                    customDelivery = _a.sent();
                                    resolve(customDelivery || []);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    DeliverySettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DeliverySettingsService_Factory() { return new DeliverySettingsService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.Storage), i0.ɵɵinject(i4.LogglyLoggerService)); }, token: DeliverySettingsService, providedIn: "root" });
    return DeliverySettingsService;
}());
export { DeliverySettingsService };
