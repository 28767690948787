import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { convertSnaps } from '../db-utils';
import { AngularFireStorage } from '@angular/fire/storage/';
import { map, first } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { PlaceModalPage } from 'src/app/pages/place-modal/place-modal.page';
import { ModalController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@angular/fire/storage";
import * as i3 from "../config/config.service";
import * as i4 from "@ionic/storage";
import * as i5 from "@ionic/angular";
var VendorService = /** @class */ (function () {
    function VendorService(angularFirestore, angularFireStorage, configService, storage, modalController) {
        this.angularFirestore = angularFirestore;
        this.angularFireStorage = angularFireStorage;
        this.configService = configService;
        this.storage = storage;
        this.modalController = modalController;
        this.vendorRef = this.angularFirestore.collection('features').doc('multiVendor');
        this.multiVendorRef = this.angularFirestore.collection('features').doc('multiVendor').collection('vendors');
        this.vendorSettings = {
            isMultiVendorAvailable: false,
            vendorBasedOnKm: false,
            vendorDistance: 0,
            exclusiveVendors: [],
            vendors: [],
            showVendorInfo: false,
            multipleVendorInvoices: false
        };
        this.userPlaceSub = new BehaviorSubject({
            place: '',
            lat: null,
            lng: null
        });
        this.userPlace = {
            place: '',
            lat: null,
            lng: null
        };
    }
    VendorService.prototype.submitVendorForm = function (formData, formFile) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var vendorData, file, ext, ref, downloadURL;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    vendorData = formData;
                                    if (!formFile) return [3 /*break*/, 4];
                                    file = formFile;
                                    ext = file.name.split('.').pop();
                                    console.log(ext);
                                    ref = this.angularFireStorage.ref("vendors/requests/" + new Date().getTime().toString() + "." + ext);
                                    return [4 /*yield*/, ref.put(file)];
                                case 1:
                                    _a.sent();
                                    return [4 /*yield*/, ref.getDownloadURL().pipe(first()).toPromise()];
                                case 2:
                                    downloadURL = _a.sent();
                                    console.log(downloadURL);
                                    vendorData.fileURL = downloadURL;
                                    return [4 /*yield*/, this.vendorRef.collection('requests').add(vendorData).then(function (docRef) {
                                            console.log("Document written with ID: ", docRef.id);
                                            resolve(docRef);
                                        })
                                            .catch(function (error) {
                                            console.error("Error adding document: ", error);
                                        })];
                                case 3:
                                    _a.sent();
                                    return [3 /*break*/, 6];
                                case 4: return [4 /*yield*/, this.vendorRef.collection('requests').add(vendorData).then(function (docRef) {
                                        console.log("Document written with ID: ", docRef.id);
                                        resolve(docRef);
                                    })
                                        .catch(function (error) {
                                        console.error("Error adding document: ", error);
                                    })];
                                case 5:
                                    _a.sent();
                                    _a.label = 6;
                                case 6: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    VendorService.prototype.getMultiVendorSettingsDataInSessionStorage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var multiVendorSettings, data;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    multiVendorSettings = sessionStorage.getItem("MultiVendorSettingsData");
                                    console.log('multiVendorSettings', multiVendorSettings);
                                    if (!(multiVendorSettings && multiVendorSettings !== 'undefined')) return [3 /*break*/, 3];
                                    data = JSON.parse(multiVendorSettings) || {};
                                    if (!(data && !(Object.keys(data).length))) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.vendorRef.valueChanges().pipe(first()).toPromise()];
                                case 1:
                                    data = _a.sent();
                                    sessionStorage.setItem("MultiVendorSettingsData", JSON.stringify(data));
                                    _a.label = 2;
                                case 2:
                                    resolve(data);
                                    return [3 /*break*/, 4];
                                case 3:
                                    resolve(null);
                                    _a.label = 4;
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    VendorService.prototype.getVendorData = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var vendor, error_1;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.multiVendorRef.doc(id).valueChanges().pipe(first()).toPromise()];
                                case 1:
                                    vendor = _a.sent();
                                    resolve(vendor);
                                    return [3 /*break*/, 3];
                                case 2:
                                    error_1 = _a.sent();
                                    console.log(error_1);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    VendorService.prototype.getActiveStatus = function (route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var multiVendorDoc, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.angularFirestore.collection('features').doc('multiVendor').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        multiVendorDoc = _a.sent();
                        if (route === 'service') {
                            return [2 /*return*/, multiVendorDoc];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    VendorService.prototype.getMultiVendorSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var multiVendorDoc, error_3;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.angularFirestore.collection('features').doc('multiVendor').valueChanges().pipe(first()).toPromise()];
                                case 1:
                                    multiVendorDoc = _a.sent();
                                    if (multiVendorDoc && multiVendorDoc.active && this.configService.environment.multiVendor) {
                                        this.vendorSettings.isMultiVendorAvailable = true;
                                        this.vendorSettings.showVendorInfo = 'showVendorInfo' in multiVendorDoc ? multiVendorDoc.showVendorInfo : false;
                                        this.vendorSettings.multipleVendorInvoices = 'multipleVendorInvoices' in multiVendorDoc ? multiVendorDoc.multipleVendorInvoices : false;
                                        if (multiVendorDoc.vendorKmBased) {
                                            this.vendorSettings.vendorBasedOnKm = true;
                                            this.vendorSettings.vendorDistance = multiVendorDoc.vendorKmDistance;
                                            this.checkUserLocation();
                                        }
                                        if (this.vendorSettings.isMultiVendorAvailable) {
                                            this.getAllExclusiveVendors();
                                        }
                                        console.log('this.vendorSettings', this.vendorSettings);
                                    }
                                    resolve(multiVendorDoc);
                                    return [3 /*break*/, 3];
                                case 2:
                                    error_3 = _a.sent();
                                    console.dir(error_3);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    VendorService.prototype.getAllExclusiveVendors = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var vendors;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.angularFirestore.collection('features').doc('multiVendor').collection('vendors', function (ref) { return ref
                                        .where('isExclusive', '==', true); })
                                        .snapshotChanges()
                                        .pipe(map(function (snaps) { return convertSnaps(snaps); })).pipe(first()).toPromise()];
                                case 1:
                                    vendors = _a.sent();
                                    if (vendors && vendors.length) {
                                        this.vendorSettings.exclusiveVendors = vendors.map(function (v) { return v.id; });
                                    }
                                    resolve(this.vendorSettings.exclusiveVendors);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    VendorService.prototype.checkUserLocation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userPlace;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get('userPlace')];
                    case 1:
                        userPlace = _a.sent();
                        if (!userPlace) {
                            this.openPlaceModal();
                        }
                        else {
                            this.userPlaceSub.next(userPlace);
                            this.userPlace = userPlace;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    VendorService.prototype.openPlaceModal = function (action) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: PlaceModalPage,
                            backdropDismiss: action === 'showClose' ? true : false
                        })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    VendorService.prototype.saveLocation = function (userPlace) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.userPlaceSub.next(userPlace);
                        this.userPlace = userPlace;
                        return [4 /*yield*/, this.storage.set('userPlace', userPlace)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    VendorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VendorService_Factory() { return new VendorService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.AngularFireStorage), i0.ɵɵinject(i3.ConfigService), i0.ɵɵinject(i4.Storage), i0.ɵɵinject(i5.ModalController)); }, token: VendorService, providedIn: "root" });
    return VendorService;
}());
export { VendorService };
