import * as tslib_1 from "tslib";
import { OnInit, ElementRef, NgZone } from '@angular/core';
import { Events, IonContent, LoadingController, ModalController, AlertController, Platform, IonRouterOutlet, ToastController } from '@ionic/angular';
import { UserService } from '../services/user/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentModalPage } from './payment-modal/payment-modal.page';
import { ChatService } from '../services/chat.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Storage } from '@ionic/storage';
import { ImageModalPage } from '../image-modal/image-modal.page';
import { ConfigService } from '../services/config/config.service';
import { OrderPaymentPage } from '../order-payment/order-payment.page';
import { RateOrderPage } from '../rating/rate-order/rate-order.page';
var ChatBotPage = /** @class */ (function () {
    function ChatBotPage(events, userService, loadingController, router, modalController, ngZone, alertController, platform, chatService, toastController, keyboard, route, storage, configService) {
        var _this = this;
        this.events = events;
        this.userService = userService;
        this.loadingController = loadingController;
        this.router = router;
        this.modalController = modalController;
        this.ngZone = ngZone;
        this.alertController = alertController;
        this.platform = platform;
        this.chatService = chatService;
        this.toastController = toastController;
        this.keyboard = keyboard;
        this.route = route;
        this.storage = storage;
        this.configService = configService;
        this.userMsgTxt = '';
        this.msg = {
            type: null,
            message: null,
            createdAt: null,
            images: null,
            isRead: null,
            author: null,
            published: null,
            mob: null,
            thumb: null,
            imageCount: null
        };
        this.allMsgs = [];
        this.chatLoader = false;
        this.enableScroll = true;
        this.showNoMsgs = false;
        this.showMsgLoader = false;
        this.showLoader = true;
        this.lastTimeBackPress = 0;
        this.timePeriodToExit = 2000;
        this.imgUrls = [];
        this.useToolbar = true;
        this.disableSendBtn = true;
        this.showSearch = false;
        this.setFirstImage = false;
        this.unsavedImages = {};
        window.addEventListener('keyboardWillShow', function () {
            // // console.log("Keyboard will Show");
            setTimeout(function () {
                _this.ngZone.run(function () {
                    if (_this.content.scrollToBottom) {
                        _this.content.scrollToBottom(0);
                    }
                });
            }, 0);
        });
        this.storage.get('unsavedImages').then(function (val) {
            if (val) {
                _this.unsavedImages = val;
                //// console.log('val of unsavedImages', this.unsavedImages);
            }
        });
    }
    ChatBotPage.prototype.ionViewWillEnter = function () {
        if (this.platform.is('android')) {
            this.useToolbar = false;
        }
        this.devWidth = this.platform.width();
        this.devHeight = this.platform.height();
        //// console.log('devWidth', this.devWidth);
        //// console.log('devHeight', this.devHeight);
        if (this.devWidth <= 500) {
            this.useThumb = true;
        }
        else if (this.devWidth > 500) {
            this.useThumb = false;
        }
        if (this.devWidth < 700) {
            this.maxMessageWidth = this.devWidth - 70;
        }
        else if (this.devWidth >= 700 && this.devWidth <= 1000) {
            this.maxMessageWidth = this.devWidth - 90;
        }
        else {
            this.maxMessageWidth = this.devWidth - 100;
        }
        if (this.devWidth < 600) {
            this.imgGridWidth = this.devWidth - 106;
        }
        else if (this.devWidth >= 600 && this.devWidth <= 700) {
            this.imgGridWidth = this.devWidth - 124;
        }
        else if (this.devWidth >= 700 && this.devWidth <= 1000) {
            this.imgGridWidth = this.devWidth - 168;
        }
        else {
            this.imgGridWidth = this.devWidth - 224;
        }
    };
    ChatBotPage.prototype.preventFocusChange = function (e) {
        e.preventDefault();
    };
    ChatBotPage.prototype.ionViewDidEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    ChatBotPage.prototype.ionViewWillLeave = function () {
        this.events.publish('chat:removeGetMsgsSubscription');
    };
    ChatBotPage.prototype.ngOnInit = function () {
        var _this = this;
        this.orderIdPrefix = this.configService.environment.orderIdPrefix;
        //// console.log('in ngOnInit');
        this.storage.get('uid').then(function (val) {
            //// console.log('user id in chat-bot', val);
            _this.uid = val;
            _this.events.publish('chat:getMsgs', _this.uid, 'user');
            _this.events.publish('user:getUserDetails');
        });
        this.initializeSubscriptions();
    };
    ChatBotPage.prototype.ngOnDestroy = function () {
        // // console.log('in ngOnDestroyed');
        this.removeSubscriptions();
    };
    ChatBotPage.prototype.scrollToBottomOnInit = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.content.scrollToBottom) {
                _this.content.scrollToBottom(0);
            }
        }, 500);
    };
    ChatBotPage.prototype.logScrolling = function ($event) {
        if ($event.detail.scrollTop === 0 && !this.searchMsg) {
            //// console.log('in scroll top zero', $event.detail.scrollTop);
            this.chatLoader = true;
            this.showNoMsgs = false;
            this.events.publish('chat:getMoreMsgs', this.uid);
        }
    };
    ChatBotPage.prototype.scrollToFirstMessage = function (elementId) {
        var y = document.getElementById(elementId).offsetTop;
        //// console.log('y position', y);
        this.content.scrollToPoint(0, y);
    };
    ChatBotPage.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('chat:publishMsgs', function (msgs) {
            _this.allMsgs = msgs;
            // if(this.newOrderStatus === true) {
            //   this.loader.dismiss();
            // }
            //// console.log('publish user msgs', msgs);
            _this.showMsgLoader = false;
            _this.showLoader = false;
            _this.scrollToBottomOnInit();
            if (_this.allMsgs) {
                _this.makeImageUrls();
            }
            _this.removeSavedImagesFromStorage();
            setTimeout(function () {
                _this.enableScroll = true;
            }, 2000);
        });
        this.events.subscribe('chat:publishMoreMsgs', function (msgs) {
            // for (const msg of msgs) {
            //   this.chatLoader = false;
            //   this.allMsgs.unshift(msg.payload.doc.data());
            // }
            _this.allMsgs = msgs;
            _this.userMsgTxt = '';
            //// console.log('more msgs', this.allMsgs);
            _this.chatLoader = false;
            _this.scrollToFirstMessage('chatMessage' + 1);
            _this.makeImageUrls();
        });
        this.events.subscribe('chat:noMoreMsgs', function () {
            _this.chatLoader = false;
            _this.showNoMsgs = true;
        });
        this.events.subscribe('user:publishUserDetails', function (user) {
            _this.userData = user;
        });
        this.events.subscribe('media:chatImageSuccess', function () {
            // this.loader.dismiss();
        });
        this.events.subscribe('user:cancelOrderSuccess', function () {
            _this.loader.dismiss();
        });
        this.events.subscribe('media:showUnsavedImages', function (msgId, imageResponse) {
            _this.unsavedImages[msgId] = imageResponse;
            _this.storage.set('unsavedImages', _this.unsavedImages);
            //// console.log('unsavedImages', this.unsavedImages);
        });
        this.events.subscribe('user:deleteProductSuccesss', function () {
            _this.presentAlert('Product Deleted Successfully!');
            _this.loading.dismiss();
        });
    };
    ChatBotPage.prototype.removeSavedImagesFromStorage = function () {
        var arrayOfUnsavedImagesIds = [];
        arrayOfUnsavedImagesIds = Object.keys(this.unsavedImages);
        for (var i = 0; i < this.allMsgs.length; i++) {
            for (var j = 0; j < arrayOfUnsavedImagesIds.length; j++) {
                if (this.allMsgs[i].id === arrayOfUnsavedImagesIds[j] && this.allMsgs[i].msgData.published === true) {
                    this.unsavedImages[arrayOfUnsavedImagesIds[j]] = null;
                    this.storage.set('unsavedImages', this.unsavedImages);
                }
            }
        }
    };
    ChatBotPage.prototype.makeImageUrls = function () {
        //// console.log('in makeImageUrls');
        if (this.allMsgs.length) {
            for (var i = 0; i < this.allMsgs.length; i++) {
                if (this.allMsgs[i].msgData.images && (this.allMsgs[i].msgData.type === "image" || this.allMsgs[i].msgData.type === "broadcast")) {
                    for (var y = 0; y < this.allMsgs[i].msgData.images.length; y++) {
                        this.imgUrls.push(this.allMsgs[i].msgData.images[y]);
                    }
                }
                else if (this.allMsgs[i].msgData.type === "order" && this.allMsgs[i].msgData.img) {
                    this.imgUrls.push(this.allMsgs[i].msgData.img);
                }
                else {
                    continue;
                }
            }
        }
        //// console.log('imgUrls', this.imgUrls);
    };
    ChatBotPage.prototype.resize = function () {
        this.myInput.nativeElement.style.height = this.myInput.nativeElement.scrollHeight + 'px';
    };
    ChatBotPage.prototype.sendMessage = function () {
        if (this.msg.message.length !== 0) {
            this.content.scrollToBottom(0);
            this.myInput.nativeElement.style.height = 40 + 'px';
            this.enableScroll = false;
            this.showMsgLoader = true;
            this.msg.type = 'txt';
            this.msg.createdAt = new Date();
            this.msg.isRead = false;
            this.msg.author = 'user';
            this.msg.published = false;
            this.allMsgs.push({ msgData: this.msg });
            this.events.publish('chat:sendMsg', this.msg, this.uid);
            this.userMsgTxt = '';
        }
    };
    ChatBotPage.prototype.uploadImage = function (files) {
        var _this = this;
        var imageResponse = [];
        for (var i = 0; i < files.length; i++) {
            var reader = new FileReader();
            reader.readAsDataURL(files.item(i));
            reader.onload = function (event) {
                var base64Image = event.target.result;
                var base64Str = base64Image.split(',');
                var size = _this.calculateImageSize(base64Str[1]);
                imageResponse.push({ url: base64Image, size: size });
                //// console.log('size of image', size);
                _this.msg.type = 'image';
                _this.msg.createdAt = new Date();
                _this.msg.isRead = false;
                _this.msg.author = 'user';
                _this.msg.published = false;
                _this.allMsgs.push(_this.msg);
                _this.events.publish('media:addChatImage', _this.uid, _this.msg, imageResponse);
            };
        }
    };
    ChatBotPage.prototype.calculateImageSize = function (base64String) {
        var padding, inBytes, base64StringLength;
        if (base64String.endsWith('==')) {
            padding = 2;
        }
        else if (base64String.endsWith('=')) {
            padding = 1;
        }
        else {
            padding = 0;
        }
        base64StringLength = base64String.length;
        inBytes = (base64StringLength / 4) * 3 - padding;
        var kbytes = inBytes / 1000;
        return kbytes;
    };
    ChatBotPage.prototype.imageZoom = function (img) {
        //// console.log("img", img);
        var imgIndex = this.imgUrls.indexOf(img);
        this.modalController.create({
            component: ImageModalPage,
            cssClass: 'photo-modal-class',
            componentProps: {
                imgs: this.imgUrls,
                index: imgIndex
            }
        }).then(function (modal) { return modal.present(); });
    };
    ChatBotPage.prototype.gridImageZoom = function (imgs) {
        this.modalController.create({
            component: ImageModalPage,
            cssClass: 'photo-modal-class',
            componentProps: {
                imgs: imgs,
                index: 0
            }
        }).then(function (modal) { return modal.present(); });
    };
    ChatBotPage.prototype.singleImageZoom = function (img) {
        this.modalController.create({
            component: ImageModalPage,
            componentProps: {
                imgs: [{ url: img }],
                index: 0
            }
        }).then(function (modal) { return modal.present(); });
    };
    ChatBotPage.prototype.calcTotalAmount = function (products) {
        var totalAmount = 0;
        for (var i = 0; i < products.length; i++) {
            totalAmount += products[i].price;
        }
        return totalAmount;
    };
    ChatBotPage.prototype.showDeleteProduct = function (msgTime) {
        // let lastOrderCheckTime = moment().subtract(environment.multipleOrdersTimeLimit,'minutes');
        // if(moment(msgTime.toDate()).isSameOrAfter(lastOrderCheckTime)) {
        //   return true;
        // } else{
        //    return false;
        // }
    };
    ChatBotPage.prototype.openPaymentModal = function () {
        this.modalController.create({
            component: PaymentModalPage,
            cssClass: 'my-custom-modal'
        })
            .then(function (modalEl) {
            modalEl.present();
        });
    };
    ChatBotPage.prototype.isDate = function (date) {
        return date instanceof Date;
    };
    ChatBotPage.prototype.cancelOrder = function (orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: 'Please Wait...',
                            })];
                    case 1:
                        _a.loader = _b.sent();
                        return [4 /*yield*/, this.loader.present()];
                    case 2:
                        _b.sent();
                        this.events.publish('user:cancelOrder', orderId);
                        return [2 /*return*/];
                }
            });
        });
    };
    ChatBotPage.prototype.cancelOrderConfirm = function (orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: 'Are you sure you want to cancel this order?',
                            buttons: [
                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'dark',
                                    handler: function (blah) {
                                        // // console.log('Confirm Cancel');
                                    }
                                }, {
                                    text: 'Okay',
                                    handler: function () {
                                        //// console.log('Confirm Okay');
                                        _this.cancelOrder(orderId);
                                    }
                                }
                            ]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChatBotPage.prototype.calculateMsgTime = function (time) {
        var hours = '0';
        var minutes = '0';
        if (time.toDate().getHours().toString().length > 1) {
            hours = time.toDate().getHours();
        }
        else {
            hours = '0' + time.toDate().getHours();
        }
        if (time.toDate().getMinutes().toString().length > 1) {
            minutes = time.toDate().getMinutes();
        }
        else {
            minutes = '0' + time.toDate().getMinutes();
        }
        return hours + ':' + minutes;
    };
    ChatBotPage.prototype.clearSearchMsg = function () {
        this.searchMsg = null;
        this.content.scrollToBottom(0);
    };
    ChatBotPage.prototype.changeInMsgInput = function () {
        this.msg.message = this.userMsgTxt;
        // // console.log('user msg txet', this.msg.message);
        this.disableSendBtn = false;
    };
    ChatBotPage.prototype.goToProfile = function () {
        this.router.navigate(['profile']);
    };
    ChatBotPage.prototype.onClickSearchBtn = function () {
        //// console.log('in onClickSearchBtn');
        this.showSearch = !this.showSearch;
    };
    ChatBotPage.prototype.onClickViewOrder = function (orderId) {
        this.router.navigate(["user-order-details/" + orderId]);
        this.dismissChatModal();
    };
    ChatBotPage.prototype.onClickDoPayment = function (orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: OrderPaymentPage,
                            cssClass: 'custom-modal',
                            componentProps: {
                                orderId: orderId,
                                userId: this.uid
                            }
                        })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChatBotPage.prototype.onClickDeleteProduct = function (orderId, msgId, productId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: 'Please Wait...',
                                duration: 10000
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        this.events.publish('user:deletePrdouctFromChatAndOrders', orderId, msgId, productId);
                        return [2 /*return*/];
                }
            });
        });
    };
    ChatBotPage.prototype.messageModifications = function (msg) {
        msg = msg.trim();
        var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        var text1 = msg.replace(exp, '<a href=\'$1\'>$1</a>');
        var exp2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        var finalText = text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
        return finalText;
    };
    ChatBotPage.prototype.onClickDeleteProductConfirm = function (orderId, msgId, productId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: 'Are you sure you want to delete this product?',
                            buttons: [
                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'dark',
                                    handler: function (blah) {
                                        // // console.log('Confirm Cancel');
                                    }
                                }, {
                                    text: 'Okay',
                                    handler: function () {
                                        //// console.log('Confirm Okay');
                                        _this.onClickDeleteProduct(orderId, msgId, productId);
                                    }
                                }
                            ]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChatBotPage.prototype.presentLoading = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: 'Please Wait...',
                            })];
                    case 1:
                        _a.loader = _b.sent();
                        return [4 /*yield*/, this.loader.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChatBotPage.prototype.presentToast = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastController.create({
                            color: 'medium',
                            message: msg,
                            duration: 2000,
                            showCloseButton: true,
                            cssClass: 'toast',
                            animated: true
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChatBotPage.prototype.presentAlert = function (desc) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: desc,
                            buttons: ['Ok']
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChatBotPage.prototype.dismissChatModal = function () {
        this.modalController.dismiss({
            'dismissed': true
        });
    };
    ChatBotPage.prototype.msgBtnClick = function (task, msgData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(task === 'orderReview')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.modalController.create({
                                component: RateOrderPage,
                                cssClass: 'custom-modal',
                                componentProps: { orderId: msgData.orderDocId }
                            })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (task === 'orderView') {
                            this.router.navigate(["user-order-details/" + msgData.orderId]);
                            this.dismissChatModal();
                        }
                        if (task === 'productView') {
                            this.router.navigate(["product-details/" + msgData.productName + "/" + msgData.productId]);
                        }
                        if (task === 'cartView') {
                            this.router.navigate(['user-cart']);
                        }
                        if (task === 'wishlistView') {
                            this.router.navigate(['user-wishlist']);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ChatBotPage.prototype.removeSubscriptions = function () {
        this.events.unsubscribe('chat:publishMsgs');
        this.events.unsubscribe('chat:publishMoreMsgs');
        this.events.unsubscribe('user:publishUserDetails');
        this.events.unsubscribe('media:chatImageSuccess');
        this.events.unsubscribe('media:showUnsavedImages');
        this.events.unsubscribe('user:cancelOrderSuccess');
        this.events.unsubscribe('user:deleteProductSuccesss');
        this.events.unsubscribe('chat:noMoreMsgs');
    };
    return ChatBotPage;
}());
export { ChatBotPage };
