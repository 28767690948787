import * as tslib_1 from "tslib";
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, first } from 'rxjs/operators';
import * as firebase from 'firebase';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
var BestSellersService = /** @class */ (function () {
    function BestSellersService(events, afs) {
        this.events = events;
        this.afs = afs;
        this.productsDataForBestSellers = [];
    }
    BestSellersService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('best-sellers:getBestSellers', function () {
            _this.getBestSellers();
        });
        this.events.subscribe('best-sellers:getBestSellersActiveStatus', function () {
            _this.getBestSellersActiveStatus();
        });
        this.events.subscribe('best-sellers:changeBestSellersStatus', function (status) {
            _this.changeBestSellersStatus(status);
        });
        this.events.subscribe('best-sellers:addBestSellerProduct', function (product) {
            _this.addBestSellerProduct(product);
        });
        this.events.subscribe('best-sellers:deleteBestSellerProduct', function (id) {
            _this.deleteBestSellerProduct(id);
        });
        this.events.subscribe('best-sellers:removeSubscriptions', function () {
            if (_this.getBSProductsSub) {
                _this.getBSProductsSub.unsubscribe();
            }
        });
        this.events.subscribe('best-sellers:getProductsForBestSellers', function () {
            _this.getProductsForBestSellers();
        });
        this.events.subscribe('best-sellers:loadMoreProductsForBestSellers', function () {
            _this.loadMoreProductsForBestSellers();
        });
        this.events.subscribe('best-sellers:loadPreviousProductsForBestSellers', function () {
            _this.loadPreviousProductsForBestSellers();
        });
        this.events.subscribe('best-sellers:getBestSellersForShopCategory', function () {
            _this.getBestSellersForShopCategory();
        });
    };
    BestSellersService.prototype.getBestSellers = function () {
        var _this = this;
        try {
            var bsProductsRef = this.afs.collection('features').doc('bestsellers').collection('products', function (ref) { return ref
                .orderBy('sortedAt', 'desc'); });
            var bsProductsSnap = bsProductsRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                var data = a.payload.doc.data();
                var id = a.payload.doc.id;
                return tslib_1.__assign({ id: id }, data);
            }); }));
            this.getBSProductsSub = bsProductsSnap.subscribe(function (res) {
                if (!res.length) {
                    _this.events.publish('best-sellers:noBestSellers');
                }
                else {
                    _this.events.publish('best-sellers:publishBestSellers', res);
                }
            });
        }
        catch (error) {
            console.dir(error);
        }
    };
    BestSellersService.prototype.getBestSellersActiveStatus = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var activeStatus, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('features').doc('bestsellers').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        activeStatus = _a.sent();
                        this.events.publish('best-sellers:publishBestSellersActiveStatus', activeStatus);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BestSellersService.prototype.changeBestSellersStatus = function (status) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        //// console.log('status in service', status);
                        return [4 /*yield*/, this.afs.collection('features').doc('bestsellers').set({
                                isActive: status
                            })];
                    case 1:
                        //// console.log('status in service', status);
                        _a.sent();
                        this.events.publish('best-sellers:changeBestSellersStatusSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BestSellersService.prototype.addBestSellerProduct = function (product) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var bestSellersData, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        bestSellersData = [];
                        return [4 /*yield*/, this.afs.collection('features').doc('bestsellers').collection('products').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        bestSellersData = _a.sent();
                        if (!(bestSellersData.length && bestSellersData.length === 10)) return [3 /*break*/, 2];
                        this.events.publish('best-sellers:maxProductsinBestSellers');
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.afs.collection('features').doc('bestsellers').collection('products').doc(product.id).set({
                            data: product.data,
                            sortedAt: firebase.firestore.FieldValue.serverTimestamp()
                        })];
                    case 3:
                        _a.sent();
                        this.events.publish('best-sellers:addBestSellerProductSuccess');
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        error_3 = _a.sent();
                        console.dir(error_3);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    BestSellersService.prototype.deleteBestSellerProduct = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('features').doc('bestsellers').collection('products').doc(id).delete()];
                    case 1:
                        _a.sent();
                        this.events.publish('best-sellers:deleteBestSellerProductSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _a.sent();
                        console.dir(error_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BestSellersService.prototype.updateBestSellersPosition = function (id, changedDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    //// console.log('id & cdate', id, changedDate);
                    return [4 /*yield*/, this.afs.collection('features').doc('bestsellers').collection('products').doc(id).update({ sortedAt: changedDate })];
                    case 1:
                        //// console.log('id & cdate', id, changedDate);
                        _a.sent();
                        this.events.publish('best-sellers:updateBestSellersPositionSuccess');
                        return [2 /*return*/];
                }
            });
        });
    };
    BestSellersService.prototype.getProductsForBestSellers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.productsDataForBestSellers = [];
                this.afs.collection('products', function (ref) { return ref
                    .orderBy('sortedAt', 'desc')
                    .limit(10); }).snapshotChanges()
                    .subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var bestSellersIds, bestSellersref, bestSellersData, index, _i, response_1, product;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!response.length) {
                                    //// console.log('No Data Available');
                                    this.events.publish('best-sellers:noProductsAvailable');
                                    return [2 /*return*/, false];
                                }
                                this.productsDataForBestSellers = [];
                                this.lastResponseForBestSellers = response[response.length - 1].payload.doc;
                                bestSellersIds = [];
                                bestSellersref = this.afs.collection('features').doc('bestsellers').collection('products');
                                return [4 /*yield*/, bestSellersref.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                        var data = a.payload.doc.data();
                                        var id = a.payload.doc.id;
                                        return tslib_1.__assign({ id: id }, data);
                                    }); })).pipe(first()).toPromise()];
                            case 1:
                                bestSellersData = _a.sent();
                                for (index = 0; index < bestSellersData.length; index++) {
                                    bestSellersIds.push(bestSellersData[index].id);
                                }
                                for (_i = 0, response_1 = response; _i < response_1.length; _i++) {
                                    product = response_1[_i];
                                    if (bestSellersIds.indexOf(product.payload.doc.id) === -1) {
                                        this.productsDataForBestSellers.push({ id: product.payload.doc.id, data: product.payload.doc.data(), isAdded: false });
                                    }
                                    else {
                                        this.productsDataForBestSellers.push({ id: product.payload.doc.id, data: product.payload.doc.data(), isAdded: true });
                                    }
                                }
                                this.events.publish('best-sellers:publishProductsForBestSellers', this.productsDataForBestSellers, this.productsDataForBestSellers.length);
                                return [2 /*return*/];
                        }
                    });
                }); }, function (error) {
                });
                return [2 /*return*/];
            });
        });
    };
    BestSellersService.prototype.loadMoreProductsForBestSellers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.afs.collection('products', function (ref) { return ref
                    .orderBy('sortedAt', 'desc')
                    .limit(10)
                    .startAfter(_this.lastResponseForBestSellers); }).snapshotChanges()
                    .subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var bestSellersIds, bestSellersref, bestSellersData, index, _i, response_2, product;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!response.length) {
                                    //// console.log('No Data Available');
                                    this.events.publish('best-sellers:productsForBestSellersLimitReached');
                                    return [2 /*return*/, false];
                                }
                                this.productsDataForBestSellers = [];
                                this.firstResponseForBestSellers = response[0].payload.doc;
                                this.lastResponseForBestSellers = response[response.length - 1].payload.doc;
                                bestSellersIds = [];
                                bestSellersref = this.afs.collection('features').doc('bestsellers').collection('products');
                                return [4 /*yield*/, bestSellersref.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                        var data = a.payload.doc.data();
                                        var id = a.payload.doc.id;
                                        return tslib_1.__assign({ id: id }, data);
                                    }); })).pipe(first()).toPromise()];
                            case 1:
                                bestSellersData = _a.sent();
                                for (index = 0; index < bestSellersData.length; index++) {
                                    bestSellersIds.push(bestSellersData[index].id);
                                }
                                for (_i = 0, response_2 = response; _i < response_2.length; _i++) {
                                    product = response_2[_i];
                                    if (bestSellersIds.indexOf(product.payload.doc.id) === -1) {
                                        this.productsDataForBestSellers.push({ id: product.payload.doc.id, data: product.payload.doc.data(), isAdded: false });
                                    }
                                    else {
                                        this.productsDataForBestSellers.push({ id: product.payload.doc.id, data: product.payload.doc.data(), isAdded: true });
                                    }
                                }
                                this.events.publish('best-sellers:publishProductsForBestSellers', this.productsDataForBestSellers);
                                return [2 /*return*/];
                        }
                    });
                }); }, function (error) {
                });
                return [2 /*return*/];
            });
        });
    };
    BestSellersService.prototype.loadPreviousProductsForBestSellers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.afs.collection('products', function (ref) { return ref
                    .orderBy('sortedAt', 'desc')
                    .endBefore(_this.firstResponseForBestSellers)
                    .limitToLast(10); }).snapshotChanges()
                    .subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var bestSellersIds, bestSellersref, bestSellersData, index, _i, response_3, product;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!response.length) {
                                    //// console.log('No Data Available');
                                    this.events.publish('best-sellers:previousProductsForBestSellersLimitReached');
                                    return [2 /*return*/, false];
                                }
                                this.productsDataForBestSellers = [];
                                this.firstResponseForBestSellers = response[0].payload.doc;
                                this.lastResponseForBestSellers = response[response.length - 1].payload.doc;
                                bestSellersIds = [];
                                bestSellersref = this.afs.collection('features').doc('bestsellers').collection('products');
                                return [4 /*yield*/, bestSellersref.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                        var data = a.payload.doc.data();
                                        var id = a.payload.doc.id;
                                        return tslib_1.__assign({ id: id }, data);
                                    }); })).pipe(first()).toPromise()];
                            case 1:
                                bestSellersData = _a.sent();
                                for (index = 0; index < bestSellersData.length; index++) {
                                    bestSellersIds.push(bestSellersData[index].id);
                                }
                                for (_i = 0, response_3 = response; _i < response_3.length; _i++) {
                                    product = response_3[_i];
                                    if (bestSellersIds.indexOf(product.payload.doc.id) === -1) {
                                        this.productsDataForBestSellers.push({ id: product.payload.doc.id, data: product.payload.doc.data(), isAdded: false });
                                    }
                                    else {
                                        this.productsDataForBestSellers.push({ id: product.payload.doc.id, data: product.payload.doc.data(), isAdded: true });
                                    }
                                }
                                this.events.publish('best-sellers:publishProductsForBestSellers', this.productsDataForBestSellers);
                                return [2 /*return*/];
                        }
                    });
                }); }, function (error) {
                });
                return [2 /*return*/];
            });
        });
    };
    BestSellersService.prototype.getBestSellersForShopCategory = function () {
        var _this = this;
        try {
            var bsProductsRef = this.afs.collection('features').doc('bestsellers').collection('products', function (ref) { return ref
                .orderBy('sortedAt', 'desc'); });
            var bsProductsSnap = bsProductsRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                var data = a.payload.doc.data();
                var id = a.payload.doc.id;
                return tslib_1.__assign({ id: id }, data);
            }); }));
            this.getBSProductsSub = bsProductsSnap.subscribe(function (res) {
                if (!res.length) {
                    _this.events.publish('best-sellers:noBestSellers');
                }
                else {
                    _this.events.publish('best-sellers:publishBestSellersForShopCategory', res);
                }
            });
        }
        catch (error) {
            console.dir(error);
        }
    };
    BestSellersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BestSellersService_Factory() { return new BestSellersService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore)); }, token: BestSellersService, providedIn: "root" });
    return BestSellersService;
}());
export { BestSellersService };
