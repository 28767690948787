import * as tslib_1 from "tslib";
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@angular/fire/storage";
var OfferService = /** @class */ (function () {
    function OfferService(events, afs, fbStorage) {
        this.events = events;
        this.afs = afs;
        this.fbStorage = fbStorage;
    }
    OfferService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('offer:saveOffer', function (data, images, oid) {
            _this.saveOffer(data, images, oid);
        });
        this.events.subscribe('offer:getOffers', function () {
            _this.getOffers();
            //// console.log('service offer:getOffers');
        });
        this.events.subscribe('offer:deleteOffer', function (oid) {
            _this.deleteOffer(oid);
        });
    };
    OfferService.prototype.saveOffer = function (data, images, oid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var offerId_1, base64Imgs, storageUrlImgs, index, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        offerId_1 = oid;
                        base64Imgs = [];
                        storageUrlImgs = [];
                        if (!oid) {
                            offerId_1 = this.afs.collection('offers').ref.doc().id;
                        }
                        if (images.length) {
                            for (index = 0; index < images.length; index++) {
                                if (images[index].url.includes('data:image/jpeg;base64,')) {
                                    base64Imgs.push(images[index]);
                                }
                                else {
                                    storageUrlImgs.push(images[index]);
                                }
                            }
                        }
                        //// console.log('base64Imgs', base64Imgs);
                        //// console.log('storageUrlImgs', storageUrlImgs);
                        return [4 /*yield*/, this.afs.collection('offers').doc(offerId_1).set({
                                name: data.name,
                                description: data.description,
                                images: storageUrlImgs,
                                sortedAt: data.sortedAt
                            })];
                    case 1:
                        //// console.log('base64Imgs', base64Imgs);
                        //// console.log('storageUrlImgs', storageUrlImgs);
                        _a.sent();
                        base64Imgs.forEach(function (img) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var imgRef;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        imgRef = this.fbStorage.ref("offers/" + offerId_1 + "/images/" + new Date().getTime().toString() + '.png');
                                        return [4 /*yield*/, imgRef.putString(img.url, 'data_url')];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        this.events.publish('offer:saveOfferSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OfferService.prototype.getOffers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var offersRef, offersSnap;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                //// console.log('service getOffers')
                try {
                    offersRef = this.afs.collection('offers', function (ref) { return ref.orderBy('sortedAt', 'desc'); });
                    offersSnap = offersRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                        var data = a.payload.doc.data();
                        var id = a.payload.doc.id;
                        return tslib_1.__assign({ id: id }, data);
                    }); }));
                    offersSnap.subscribe(function (res) {
                        _this.events.publish('offer:publishOffers', res);
                        //// console.log('offers', res);
                    });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    OfferService.prototype.updateOffersPosition = function (id, changedDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    //// console.log('id & cdate', id, changedDate);
                    return [4 /*yield*/, this.afs.doc("offers/" + id).update({ sortedAt: changedDate })];
                    case 1:
                        //// console.log('id & cdate', id, changedDate);
                        _a.sent();
                        this.events.publish('offer:updateOffersPostionSucess');
                        return [2 /*return*/];
                }
            });
        });
    };
    OfferService.prototype.deleteOffer = function (oid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('offers').doc(oid).delete()];
                    case 1:
                        _a.sent();
                        this.events.publish('offer:deleteOfferSucess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OfferService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OfferService_Factory() { return new OfferService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.AngularFireStorage)); }, token: OfferService, providedIn: "root" });
    return OfferService;
}());
export { OfferService };
