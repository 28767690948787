import * as tslib_1 from "tslib";
import { ModalController, Platform } from '@ionic/angular';
import { PaymentModulePage } from 'src/app/pages/payment-module/payment-module.page';
import { Storage } from '@ionic/storage';
import * as firebase from 'firebase';
import { ConfigService } from '../config/config.service';
import { UserService } from '../user/user.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { LabelService } from '../label/label.service';
import { SharedNewService } from '../shared-new/shared-new.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { first } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@ionic/storage";
import * as i3 from "../config/config.service";
import * as i4 from "../user/user.service";
import * as i5 from "@angular/fire/firestore";
import * as i6 from "../label/label.service";
import * as i7 from "../shared-new/shared-new.service";
import * as i8 from "@angular/fire/storage";
var PaymentService = /** @class */ (function () {
    function PaymentService(modalController, storage, configService, userService, platform, afs, labelService, sharedNewService, fbStorage) {
        this.modalController = modalController;
        this.storage = storage;
        this.configService = configService;
        this.userService = userService;
        this.platform = platform;
        this.afs = afs;
        this.labelService = labelService;
        this.sharedNewService = sharedNewService;
        this.fbStorage = fbStorage;
    }
    PaymentService.prototype.openPaymentModal = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: PaymentModulePage,
                            cssClass: 'custom-modal',
                            backdropDismiss: false,
                            componentProps: { paymentData: data },
                        })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentService.prototype.getPartialPaymentObj = function (data) {
        return {
            cod: data.order.partialPayment.cod,
            online: data.order.amount,
            status: 'pending'
        };
    };
    PaymentService.prototype.razorpayMethod = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var paymentRes, createOrderInRazorpay, createOrderRes, razorpayOrderId, historyObj, storeInfo, options, razorpay;
                        var _this = this;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    data.order.amount = this.getPayableAmount(data.order);
                                    paymentRes = {
                                        completed: false,
                                        mode: 'razorpay',
                                        status: 'pending',
                                        details: null,
                                        walletAmount: data.order.walletAmount,
                                        cashbackAmount: data.order.cashbackAmount
                                    };
                                    if (data.order.partialPayment.status) {
                                        paymentRes.partial = this.getPartialPaymentObj(data);
                                    }
                                    createOrderInRazorpay = firebase.functions().httpsCallable('payments-razorpay_createOrder');
                                    return [4 /*yield*/, createOrderInRazorpay({ amount: Math.round(data.order.amount * 100), orderDocId: data.order.id, collection: data.order.collection })];
                                case 1:
                                    createOrderRes = _a.sent();
                                    razorpayOrderId = createOrderRes.data && createOrderRes.data.orderId ? createOrderRes.data.orderId : '';
                                    console.log('razorpayOrderId', razorpayOrderId);
                                    historyObj = {
                                        orderId: data.order.id,
                                        orderAmount: data.order.amount,
                                        userId: data.order.userId,
                                        type: data.order.collection,
                                        mode: 'razorpay',
                                        walletAmount: data.order.walletAmount,
                                        cashbackAmount: data.order.cashbackAmount
                                    };
                                    if (!razorpayOrderId) return [3 /*break*/, 3];
                                    return [4 /*yield*/, this.storage.get('storeInfo')];
                                case 2:
                                    storeInfo = _a.sent();
                                    options = {
                                        order_id: razorpayOrderId,
                                        description: this.configService.environment.razorpay.description,
                                        currency: this.configService.environment.razorpay.currency,
                                        key: data.paymentCreds.key,
                                        amount: Math.round(data.order.amount * 100),
                                        name: storeInfo.storeName ? storeInfo.storeName : '',
                                        image: this.configService.environment.razorpay.image,
                                        prefill: {
                                            method: data.method,
                                            contact: this.userService.getPhoneNo(),
                                            name: this.userService.getUserName(),
                                            email: this.userService.getUserEmail() || 'xyz@gmail.com',
                                        },
                                        theme: this.configService.environment.razorpay.theme,
                                        modal: {
                                            ondismiss: function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                                return tslib_1.__generator(this, function (_a) {
                                                    resolve({ status: 'failed', response: paymentRes });
                                                    return [2 /*return*/];
                                                });
                                            }); }
                                        },
                                        handler: function (handlerResponse) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                            var verifySignature, apiBody, txnRes, verifySignatureRes;
                                            return tslib_1.__generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        console.log('handlerResponse', handlerResponse);
                                                        verifySignature = firebase.functions().httpsCallable('payments-razorpay_verifySignature');
                                                        apiBody = {
                                                            razorpay_payment_id: handlerResponse.razorpay_payment_id,
                                                            razorpay_order_id: handlerResponse.razorpay_order_id,
                                                            razorpay_signature: handlerResponse.razorpay_signature,
                                                        };
                                                        txnRes = { paymentId: apiBody.razorpay_payment_id };
                                                        historyObj.details = txnRes;
                                                        return [4 /*yield*/, this.createPaymentHistory(historyObj)];
                                                    case 1:
                                                        _a.sent();
                                                        paymentRes.details = txnRes;
                                                        return [4 /*yield*/, verifySignature(apiBody)];
                                                    case 2:
                                                        verifySignatureRes = _a.sent();
                                                        console.log('verifySignatureRes', verifySignatureRes);
                                                        if (verifySignatureRes.data.signatureIsValid) {
                                                            paymentRes.completed = true;
                                                            paymentRes.status = 'completed';
                                                            resolve({ status: 'success', response: paymentRes });
                                                        }
                                                        else {
                                                            resolve({ status: 'failed', response: paymentRes });
                                                        }
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); }
                                    };
                                    razorpay = new Razorpay(options);
                                    razorpay.open();
                                    razorpay.on('payment.failed', function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                        var paymentId, txnRes;
                                        return tslib_1.__generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    console.log('failure response', response);
                                                    paymentId = response.error && response.error.metadata && response.error.metadata.payment_id ? response.error.metadata.payment_id : '';
                                                    if (!paymentId) return [3 /*break*/, 2];
                                                    txnRes = { paymentId: paymentId };
                                                    historyObj.details = txnRes;
                                                    return [4 /*yield*/, this.createPaymentHistory(historyObj)];
                                                case 1:
                                                    _a.sent();
                                                    paymentRes.details = txnRes;
                                                    _a.label = 2;
                                                case 2:
                                                    resolve({ status: 'failed', response: paymentRes });
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); });
                                    return [3 /*break*/, 4];
                                case 3:
                                    resolve({ status: 'failed', response: paymentRes });
                                    _a.label = 4;
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    PaymentService.prototype.cashMethod = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var paymentRes, historyObj;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    data.order.amount = this.getPayableAmount(data.order);
                                    paymentRes = {
                                        completed: false,
                                        mode: 'cash',
                                        details: {
                                            amount: data.order.amount
                                        }
                                    };
                                    if (!(data.order.walletAmount || data.order.cashbackAmount)) return [3 /*break*/, 2];
                                    historyObj = {
                                        orderId: data.order.id,
                                        orderAmount: data.order.amount,
                                        details: {
                                            amount: data.order.amount
                                        },
                                        userId: data.order.userId,
                                        type: data.order.collection,
                                        mode: 'cash',
                                        walletAmount: data.order.walletAmount,
                                        cashbackAmount: data.order.cashbackAmount
                                    };
                                    return [4 /*yield*/, this.createPaymentHistory(historyObj)];
                                case 1:
                                    _a.sent();
                                    _a.label = 2;
                                case 2:
                                    resolve({ status: 'success', response: paymentRes });
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    PaymentService.prototype.paytmMethod = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var paymentRes, mobileNo_1, getCheckSum;
                        var _this = this;
                        return tslib_1.__generator(this, function (_a) {
                            paymentRes = {
                                completed: false,
                                mode: 'paytm',
                                status: 'pending',
                                details: null
                            };
                            if (data.order.partialPayment.status) {
                                paymentRes.partial = this.getPartialPaymentObj(data);
                            }
                            try {
                                mobileNo_1 = this.userService.getPhoneNo().slice(3);
                                data.order.amount = this.getPayableAmount(data.order);
                                getCheckSum = firebase.functions().httpsCallable('payments-getCheckSumApi');
                                getCheckSum({
                                    orderId: data.order.id.toString(),
                                    customerId: data.order.userId,
                                    phoneNo: mobileNo_1,
                                    txnAmount: data.order.amount.toString(),
                                }).then(function (result) {
                                    console.log('checksum:', result.data.checksum);
                                    var paytmParams = {
                                        MID: result.data.mid,
                                        ORDER_ID: data.order.id.toString(),
                                        CUST_ID: data.order.userId,
                                        CHANNEL_ID: _this.configService.environment.paytm.CHANNEL_ID,
                                        TXN_AMOUNT: data.order.amount.toString(),
                                        WEBSITE: _this.configService.environment.paytm.WEBSITE,
                                        CALLBACK_URL: "https://securegw-stage.paytm.in/theia/paytmCallback?ORDER_ID=" + data.order.id.toString(),
                                        INDUSTRY_TYPE_ID: _this.configService.environment.paytm.INDUSTRY_TYPE_ID,
                                        MOBILE_NO: mobileNo_1,
                                        CHECKSUMHASH: result.data.checksum,
                                        ENVIRONMENT: _this.configService.environment.paytm.ENVIRONMENT
                                    };
                                    var successCallback = function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                        var historyObj;
                                        return tslib_1.__generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    if (!(response.STATUS == "TXN_SUCCESS")) return [3 /*break*/, 2];
                                                    historyObj = {
                                                        orderId: data.order.id,
                                                        orderAmount: data.order.amount,
                                                        details: response,
                                                        userId: data.order.userId,
                                                        type: data.order.collection,
                                                        mode: 'paytm',
                                                        walletAmount: data.order.walletAmount,
                                                        cashbackAmount: data.order.cashbackAmount
                                                    };
                                                    return [4 /*yield*/, this.createPaymentHistory(historyObj)];
                                                case 1:
                                                    _a.sent();
                                                    paymentRes.details = response;
                                                    paymentRes.completed = true;
                                                    paymentRes.status = 'completed';
                                                    resolve({ status: 'success', response: paymentRes });
                                                    return [3 /*break*/, 3];
                                                case 2:
                                                    resolve({ status: 'failed', response: paymentRes });
                                                    _a.label = 3;
                                                case 3: return [2 /*return*/];
                                            }
                                        });
                                    }); };
                                    var failureCallback = function (error) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                        return tslib_1.__generator(this, function (_a) {
                                            resolve({ status: 'failed', response: paymentRes });
                                            return [2 /*return*/];
                                        });
                                    }); };
                                    paytm.startPayment(paytmParams, successCallback, failureCallback);
                                }).catch(function (error) {
                                    resolve({ status: 'failed', response: paymentRes });
                                });
                            }
                            catch (error) {
                                resolve({ status: 'failed', response: paymentRes });
                            }
                            return [2 /*return*/];
                        });
                    }); })];
            });
        });
    };
    PaymentService.prototype.walletMethod = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var paymentRes, historyObj;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    paymentRes = {
                                        completed: true,
                                        mode: 'wallet',
                                        status: 'completed'
                                    };
                                    historyObj = {
                                        orderId: data.order.id,
                                        orderAmount: data.order.amount,
                                        details: {
                                            amount: data.order.amount
                                        },
                                        userId: data.order.userId,
                                        type: data.order.collection,
                                        mode: 'wallet',
                                        walletAmount: data.order.walletAmount,
                                        cashbackAmount: data.order.cashbackAmount
                                    };
                                    return [4 /*yield*/, this.createPaymentHistory(historyObj)];
                                case 1:
                                    _a.sent();
                                    resolve({ status: 'success', response: paymentRes });
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    PaymentService.prototype.upiManaulMethod = function (data, paymentImg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var imgRef, downloadURL, paymentRes, historyObj;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    imgRef = this.fbStorage.ref("upiManualPayment/" + data.order.id + "/image/" + new Date().getTime().toString() + '.png');
                                    return [4 /*yield*/, imgRef.putString(paymentImg, 'data_url')];
                                case 1:
                                    _a.sent();
                                    return [4 /*yield*/, imgRef.getDownloadURL().pipe(first()).toPromise()];
                                case 2:
                                    downloadURL = _a.sent();
                                    paymentRes = {
                                        completed: true,
                                        mode: 'upiManual',
                                        screenshot: downloadURL,
                                        status: 'completed'
                                    };
                                    if (!(data.order.walletAmount || data.order.cashbackAmount)) return [3 /*break*/, 4];
                                    historyObj = {
                                        orderId: data.order.id,
                                        orderAmount: data.order.amount,
                                        details: {
                                            amount: data.order.amount
                                        },
                                        userId: data.order.userId,
                                        type: data.order.collection,
                                        mode: 'wallet',
                                        walletAmount: data.order.walletAmount,
                                        cashbackAmount: data.order.cashbackAmount
                                    };
                                    return [4 /*yield*/, this.createPaymentHistory(historyObj)];
                                case 3:
                                    _a.sent();
                                    _a.label = 4;
                                case 4:
                                    resolve({ status: 'success', response: paymentRes });
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    PaymentService.prototype.customOptionMethod = function (data, userResponse) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var downloadURL, imgRef, paymentRes, historyObj;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    downloadURL = '';
                                    if (!userResponse.image) return [3 /*break*/, 3];
                                    imgRef = this.fbStorage.ref("customPayment/" + data.order.id + "/image/" + new Date().getTime().toString() + '.png');
                                    return [4 /*yield*/, imgRef.putString(userResponse.image, 'data_url')];
                                case 1:
                                    _a.sent();
                                    return [4 /*yield*/, imgRef.getDownloadURL().pipe(first()).toPromise()];
                                case 2:
                                    downloadURL = _a.sent();
                                    _a.label = 3;
                                case 3:
                                    paymentRes = {
                                        completed: true,
                                        mode: 'custom',
                                        optionName: userResponse.optionName,
                                        screenshot: downloadURL,
                                        textDetails: userResponse.textDetails,
                                        status: 'completed'
                                    };
                                    if (!(data.order.walletAmount || data.order.cashbackAmount)) return [3 /*break*/, 5];
                                    historyObj = {
                                        orderId: data.order.id,
                                        orderAmount: data.order.amount,
                                        details: {
                                            amount: data.order.amount
                                        },
                                        userId: data.order.userId,
                                        type: data.order.collection,
                                        mode: 'wallet',
                                        walletAmount: data.order.walletAmount,
                                        cashbackAmount: data.order.cashbackAmount
                                    };
                                    return [4 /*yield*/, this.createPaymentHistory(historyObj)];
                                case 4:
                                    _a.sent();
                                    _a.label = 5;
                                case 5:
                                    resolve({ status: 'success', response: paymentRes });
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    PaymentService.prototype.handlePaymentRes = function (paymentRes, paymentData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alertMessage;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs.collection(paymentData.order.collection).doc(paymentData.order.id).update({
                            payment: tslib_1.__assign({}, paymentRes.response),
                            status: paymentRes.status === 'success' ? 'accepted' : 'pending'
                            // totalGst: firebase.firestore.FieldValue.increment(paymentRes.response.extraChargeOnPayment.gst)
                        })];
                    case 1:
                        _a.sent();
                        alertMessage = paymentRes.status === 'success' ? this.labelService.labels['PAYMENT_SERVICE']['payment_success_msg'] :
                            paymentRes.mode === 'razorpay' ? this.labelService.labels['PAYMENT_SERVICE']['razorpay_payment_failed_msg'] :
                                this.labelService.labels['PAYMENT_SERVICE']['payment_failed_msg'];
                        this.sharedNewService.presentAlert({ msg: alertMessage, page: paymentData.successRoute, backdropDismiss: false });
                        if (this.modalController) {
                            this.modalController.dismiss();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentService.prototype.createPaymentHistory = function (historyObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentHistoryObj;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        paymentHistoryObj = tslib_1.__assign({ paidAt: new Date(), status: 'successful' }, historyObj);
                        if (historyObj.mode === 'razorpay') {
                            paymentHistoryObj['status'] = 'pending';
                        }
                        return [4 /*yield*/, this.afs.collection('payment').doc('history').collection('payments').add(paymentHistoryObj)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentService.prototype.getPayableAmount = function (order) {
        console.log('order', order);
        return order.partialPayment.status ? order.partialPayment.online.amount : (order.amount - (order.walletAmount + order.cashbackAmount));
    };
    PaymentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentService_Factory() { return new PaymentService(i0.ɵɵinject(i1.ModalController), i0.ɵɵinject(i2.Storage), i0.ɵɵinject(i3.ConfigService), i0.ɵɵinject(i4.UserService), i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i5.AngularFirestore), i0.ɵɵinject(i6.LabelService), i0.ɵɵinject(i7.SharedNewService), i0.ɵɵinject(i8.AngularFireStorage)); }, token: PaymentService, providedIn: "root" });
    return PaymentService;
}());
export { PaymentService };
