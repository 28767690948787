import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Events, AlertController, LoadingController, NavController, ToastController, ModalController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { AdminSettingsService } from 'src/app/services/admin-settings/admin-settings.service';
import { StripePage } from '../payment-ui/stripe/stripe.page';
import { OrderService } from 'src/app/services/order/order.service';
import { PaypalPage } from '../payment-ui/paypal/paypal.page';
import { SharedService } from 'src/app/services/shared/shared.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { UpiManualPaymentPage } from '../upi-manual-payment/upi-manual-payment.page';
import { CustomPaymentOptionPage } from '../custom-payment-option/custom-payment-option.page';
var PaymentModulePage = /** @class */ (function () {
    function PaymentModulePage(events, alertController, loadingController, navCtrl, toastController, labelService, configService, adminSettingsService, modalController, orderService, sharedService, paymentService) {
        this.events = events;
        this.alertController = alertController;
        this.loadingController = loadingController;
        this.navCtrl = navCtrl;
        this.toastController = toastController;
        this.labelService = labelService;
        this.configService = configService;
        this.adminSettingsService = adminSettingsService;
        this.modalController = modalController;
        this.orderService = orderService;
        this.sharedService = sharedService;
        this.paymentService = paymentService;
        this.paytmActive = false;
        this.razorpayActive = false;
        this.showLoader = true;
        this.isCod = false;
        this.walletActive = true;
        this.minOrderAmntToUseWallet = 0;
        this.maxWalletAmntPerOrder = 1000;
        this.walletUsed = false;
        this.walletAmount = 0;
        this.envPaytmActive = true;
        this.SHARED_LABELS = {};
        this.AUTO_CONFIRM_PAYMENT_LABELS = {};
        this.upiManual = {
            active: false,
            upiId: '',
            qrCode: '',
            successRoute: ''
        };
        this.cashbackAmount = 0;
        this.cashbackBalance = 0;
        this.isCodAvailableForCoupon = true;
        this.codPercent = 100;
        this.partialPayment = {
            status: false,
            cod: 0,
            online: {
                amount: 0,
                completed: false
            }
        };
        this.paymentType = 'full'; // value: full || partial
        this.customOption = {
            active: false,
            name: '',
            details: '',
            image: {
                url: ''
            },
            textMandatory: false,
            imageMandatory: false,
            successRoute: ''
        };
        this.stripeData = {
            active: false
        };
        this.paymentGateways = ['paypal', 'cashfree'];
        this.paypalObj = {
            active: false
        };
        this.cashfreeObj = {
            active: false
        };
        this.extraCharge = {
            cash: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 },
            razorpay: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 },
            paytm: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 },
            stripe: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 },
            paypal: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 },
            cashfree: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 }
        };
        // this.route.queryParams.subscribe(params => {
        //     if (this.router.getCurrentNavigation().extras.state) {
        //         this.isCodAvailableForCoupon = this.router.getCurrentNavigation().extras.state.isCodAvailableForCoupon;
        //     }
        // });
    }
    PaymentModulePage.prototype.ngOnInit = function () {
        this.envPaytmActive = this.configService.environment.envPaytmActive;
    };
    PaymentModulePage.prototype.ionViewDidEnter = function () {
        this.events.publish('admin-settings:getPaytmData');
        this.events.publish('admin-settings:getRazorPayData');
        this.events.publish('admin-settings:getPaymentInfoData');
        this.events.publish('wallet:getUserWalletDetails', this.paymentData.order.userId);
        this.events.publish('wallet:getWalletSettings');
        this.initializeSubscriptions();
        this.currencyCode = this.configService.environment.currencyCode;
        this.taxType = this.configService.environment.taxType;
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.AUTO_CONFIRM_PAYMENT_LABELS = this.labelService.labels['AUTO_CONFIRM_PAYMENT'];
        this.getStripeDetails();
        this.getGateways();
    };
    PaymentModulePage.prototype.ionViewWillLeave = function () {
        this.removeSubscription();
    };
    PaymentModulePage.prototype.initializeSubscriptions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _i, _a, paymentGateway, paymentGatewayData;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.events.subscribe('user:setPaymentModeOfOrderByUserSuccessfully', function () {
                            _this.loading.dismiss();
                            _this.presentAlert("" + _this.AUTO_CONFIRM_PAYMENT_LABELS['payment_mode_set_msg']);
                        });
                        this.events.subscribe('admin-settings:publishPaytmData', function (data) {
                            if (data) {
                                _this.paytmActive = data.active;
                                _this.extraCharge.paytm = 'extraChargePaytm' in data ? data.extraChargePaytm : _this.getInitObjForExtraCharge();
                            }
                        });
                        this.events.subscribe('admin-settings:publishRazorPayData', function (data) {
                            if (data && data.active && data.id !== '') {
                                _this.razorpayActive = data.active;
                                _this.razorpayId = data.id;
                                _this.extraCharge.razorpay = 'extraChargeRazorpay' in data ? data.extraChargeRazorpay : _this.getInitObjForExtraCharge();
                            }
                            _this.showLoader = false;
                        });
                        this.events.subscribe('admin-settings:publishPaymentInfoData', function (data) {
                            var isCod = typeof data.isCod !== 'undefined' ? data.isCod : true;
                            var extraNoCodChecks = false;
                            // if (isCod) {
                            //     for (const pdt of this.orderData.products) {
                            //         if ((pdt.hasOwnProperty('orderType') && pdt.orderType === 'membership') || ('isCod' in pdt && !pdt.isCod)) {
                            //             extraNoCodChecks = true;
                            //             break;
                            //         }
                            //     }
                            // }
                            if (extraNoCodChecks || !_this.isCodAvailableForCoupon) {
                                isCod = false;
                            }
                            _this.isCod = isCod;
                            _this.upiManual = data.hasOwnProperty('upiManual') ? data.upiManual : _this.upiManual;
                            _this.codPercent = 'codPercentage' in data ? data.codPercentage : 100;
                            _this.partialPayment.status = _this.codPercent < 100 ? true : false;
                            _this.customOption = 'custom' in data ? data.custom : _this.customOption;
                            _this.extraCharge.cash = 'extraCharge' in data ? data.extraCharge : _this.getInitObjForExtraCharge();
                            _this.isGstApplicable = 'isGstApplicable' in data ? data.isGstApplicable : false;
                        });
                        this.events.subscribe('order:ac_modeSetToCashSuccess', function () {
                            _this.loading.dismiss();
                            _this.presentAlert("" + _this.AUTO_CONFIRM_PAYMENT_LABELS['order_placed_successfully']);
                        });
                        this.events.subscribe('order:ac_completePaymentWithWalletSuccess', function () {
                            _this.loading.dismiss();
                            _this.presentAlert("" + _this.AUTO_CONFIRM_PAYMENT_LABELS['order_placed_successfully']);
                        });
                        this.events.subscribe('wallet:publishUserWalletDetails', function (data) {
                            if (data) {
                                _this.walletBalance = data.wallet ? data.wallet.balance : 0;
                                _this.cashbackBalance = data.wallet && data.wallet.cashback && data.wallet.cashback > 0 ? data.wallet.cashback : 0;
                                console.log('walletBalance', _this.walletBalance);
                            }
                        });
                        this.events.subscribe('wallet:publishWalletSettings', function (data) {
                            console.log('publishWalletSettings', data);
                            if (!_this.isEmptyObj(data)) {
                                _this.walletActive = typeof data.active !== 'undefined' ? data.active : true;
                                _this.minOrderAmntToUseWallet = data.minOrderAmnt ? data.minOrderAmnt : 0;
                                _this.maxWalletAmntPerOrder = data.maxWalletAmntPerOrder ? data.maxWalletAmntPerOrder : 1000;
                            }
                        });
                        _i = 0, _a = this.paymentGateways;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        paymentGateway = _a[_i];
                        return [4 /*yield*/, this.adminSettingsService.getPaymentGateway(paymentGateway)];
                    case 2:
                        paymentGatewayData = _b.sent();
                        if (paymentGatewayData && paymentGatewayData.active) {
                            if (paymentGateway == 'paypal') {
                                this.paypalObj = paymentGatewayData;
                            }
                            if (paymentGateway == 'cashfree') {
                                this.cashfreeObj = paymentGatewayData;
                            }
                        }
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PaymentModulePage.prototype.completePaymentWithWallet = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alertRes, paymentRes;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.sharedService.presentAlertConfirm(this.SHARED_LABELS['payment_mode_alert_msg'])];
                    case 1:
                        alertRes = _a.sent();
                        if (!alertRes) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.sharedService.presentLoading()];
                    case 2:
                        _a.sent();
                        this.paymentData.order.walletAmount = this.walletAmount;
                        this.paymentData.order.cashbackAmount = this.cashbackAmount;
                        return [4 /*yield*/, this.paymentService.walletMethod(this.paymentData)];
                    case 3:
                        paymentRes = _a.sent();
                        paymentRes.response.extraChargeOnPayment = { charge: 0, name: '', gst: 0 };
                        this.sharedService.dismissLoading();
                        this.paymentService.handlePaymentRes(paymentRes, this.paymentData);
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentModulePage.prototype.onClickPaymentMode = function (mode, method) {
        if (method === void 0) { method = ''; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alertRes, paymentRes, extraChargeOnPayment, _a, paymentImgRes, customOptionResponse;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.sharedService.presentAlertConfirm(this.SHARED_LABELS['payment_mode_alert_msg'])];
                    case 1:
                        alertRes = _b.sent();
                        if (!alertRes) {
                            return [2 /*return*/];
                        }
                        this.checkPartialPayment();
                        this.paymentData.order.walletAmount = this.walletAmount;
                        this.paymentData.order.cashbackAmount = this.cashbackAmount;
                        paymentRes = {};
                        extraChargeOnPayment = { charge: 0, name: '', gst: 0 };
                        if (mode in this.extraCharge) {
                            extraChargeOnPayment = this.getExtraChargeAmount(this.extraCharge[mode]);
                        }
                        return [4 /*yield*/, this.sharedService.presentLoading()];
                    case 2:
                        _b.sent();
                        _a = mode;
                        switch (_a) {
                            case 'cash': return [3 /*break*/, 3];
                            case 'razorpay': return [3 /*break*/, 5];
                            case 'paytm': return [3 /*break*/, 7];
                            case 'upiManual': return [3 /*break*/, 9];
                            case 'custom': return [3 /*break*/, 13];
                            case 'stripe': return [3 /*break*/, 17];
                            case 'paypal': return [3 /*break*/, 18];
                            case 'cashfree': return [3 /*break*/, 19];
                        }
                        return [3 /*break*/, 20];
                    case 3: return [4 /*yield*/, this.paymentService.cashMethod(this.paymentData)];
                    case 4:
                        paymentRes = _b.sent();
                        return [3 /*break*/, 20];
                    case 5:
                        this.paymentData = tslib_1.__assign({}, this.paymentData, { paymentCreds: { key: this.razorpayId } });
                        this.paymentData.method = method;
                        return [4 /*yield*/, this.paymentService.razorpayMethod(this.paymentData)];
                    case 6:
                        paymentRes = _b.sent();
                        return [3 /*break*/, 20];
                    case 7: return [4 /*yield*/, this.paymentService.paytmMethod(this.paymentData)];
                    case 8:
                        paymentRes = _b.sent();
                        return [3 /*break*/, 20];
                    case 9:
                        this.sharedService.dismissLoading();
                        this.upiManual.successRoute = this.paymentData.successRoute;
                        return [4 /*yield*/, this.openUpiManualModal(this.upiManual)];
                    case 10:
                        paymentImgRes = _b.sent();
                        return [4 /*yield*/, this.sharedService.presentLoading()];
                    case 11:
                        _b.sent();
                        return [4 /*yield*/, this.paymentService.upiManaulMethod(this.paymentData, paymentImgRes.paymentImg)];
                    case 12:
                        paymentRes = _b.sent();
                        return [3 /*break*/, 20];
                    case 13:
                        this.sharedService.dismissLoading();
                        this.customOption.successRoute = this.paymentData.successRoute;
                        return [4 /*yield*/, this.openCustomOptionModal(this.customOption)];
                    case 14:
                        customOptionResponse = _b.sent();
                        return [4 /*yield*/, this.sharedService.presentLoading()];
                    case 15:
                        _b.sent();
                        return [4 /*yield*/, this.paymentService.customOptionMethod(this.paymentData, customOptionResponse.response)];
                    case 16:
                        paymentRes = _b.sent();
                        return [3 /*break*/, 20];
                    case 17: 
                    // this.presentStripeModal();
                    return [3 /*break*/, 20];
                    case 18: 
                    // this.presentPaypalModal()
                    return [3 /*break*/, 20];
                    case 19: 
                    // this.payWithCashfree();
                    return [3 /*break*/, 20];
                    case 20:
                        paymentRes.response.extraChargeOnPayment = extraChargeOnPayment;
                        paymentRes.response.walletAmount = this.walletAmount;
                        paymentRes.response.cashbackAmount = this.cashbackAmount;
                        this.sharedService.dismissLoading();
                        this.paymentService.handlePaymentRes(paymentRes, this.paymentData);
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentModulePage.prototype.getExtraChargeAmount = function (extraChargeObj) {
        var extraCharge = 0;
        extraChargeObj = this.paymentType === 'partial' ? this.extraCharge.cash : extraChargeObj;
        var gst = 0;
        if (Object.keys('extraChargeObj').length && extraChargeObj.charge) {
            if (extraChargeObj.type === 'flat') {
                extraCharge = extraChargeObj.charge;
            }
            else {
                extraCharge = (this.paymentData.order.amount - (this.walletAmount + this.cashbackAmount)) * (extraChargeObj.charge / 100);
                if (extraCharge > extraChargeObj.maxCharge) {
                    extraCharge = extraChargeObj.maxCharge;
                }
            }
            this.paymentData.order.amount += extraCharge;
            gst = this.calcGst(extraCharge);
        }
        return { charge: extraCharge, name: extraChargeObj.chargeName || '', gst: gst };
    };
    PaymentModulePage.prototype.calcGst = function (extraCharge) {
        return (extraCharge - (extraCharge / (1 + (this.paymentData.order.gst.value / 100))));
        // this.orderData.defaultGst += gstOnExtraCharge;
    };
    // calcGst(extraCharge) {
    //     let allGst = [];
    //     let gstOnExtraCharge = 0;
    //     this.orderData.products.map((p) => {
    //         if (p.gst && this.isGstApplicable) {
    //             allGst.push(p.gst);}
    //     });
    //     if (allGst.length) {
    //         let minGst = 0;
    //         minGst = Math.min(...allGst);
    //         gstOnExtraCharge = (extraCharge - (extraCharge / (1 + (minGst / 100))));
    //         this.orderData.defaultGst += gstOnExtraCharge;
    //         this.orderData.deliveryGstObj.extraChargeGst = (this.orderData.deliveryGstObj.extraChargeGst || 0) + gstOnExtraCharge;
    //     }
    // }
    PaymentModulePage.prototype.payWithCashfree = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.orderService.createOrderCashfree(this.paymentData, true)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentModulePage.prototype.checkPartialPayment = function () {
        var partial = tslib_1.__assign({}, this.partialPayment);
        if (this.paymentType === 'partial') {
            partial.status = true;
        }
        else {
            partial.status = false;
        }
        this.paymentData.order.partialPayment = partial;
    };
    PaymentModulePage.prototype.isEmptyObj = function (object) {
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    };
    PaymentModulePage.prototype.onClickUseWallet = function () {
        if (this.walletUsed) {
            this.walletUsed = false;
            this.walletAmount = 0;
            this.cashbackAmount = 0;
        }
        else {
            this.getUsableWalletAmnt();
            this.walletUsed = true;
        }
    };
    PaymentModulePage.prototype.getUsableWalletAmnt = function () {
        var walletUsed = 0;
        var cashbackUsed = 0;
        if (this.paymentData.order.amount < this.minOrderAmntToUseWallet) {
            walletUsed = this.paymentData.order.amount >= this.walletBalance ? this.walletBalance : this.paymentData.order.amount;
            this.walletAmount = walletUsed;
        }
        else {
            cashbackUsed = this.paymentData.order.amount >= this.cashbackBalance ? this.cashbackBalance : this.paymentData.order.amount;
            cashbackUsed = cashbackUsed > this.maxWalletAmntPerOrder ? this.maxWalletAmntPerOrder : cashbackUsed;
            this.cashbackAmount = cashbackUsed;
            if (this.paymentData.order.amount - this.cashbackAmount > 0) {
                walletUsed = (this.paymentData.order.amount - this.cashbackAmount) >= this.walletBalance ? this.walletBalance : (this.paymentData.order.amount - this.cashbackAmount);
                this.walletAmount = walletUsed;
            }
        }
    };
    PaymentModulePage.prototype.setPaymentType = function (type) {
        this.paymentType = type;
        if (type === 'partial') {
            this.partialPayment['cod'] = parseFloat((this.getFinalAmount() * (this.codPercent / 100)).toFixed(2));
            this.partialPayment['online']['amount'] = parseFloat((this.getFinalAmount() - this.partialPayment['cod']).toFixed(2));
        }
    };
    PaymentModulePage.prototype.getFinalAmount = function () {
        return this.paymentData.order.amount - (this.walletAmount + this.cashbackAmount);
    };
    PaymentModulePage.prototype.presentToast = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastController.create({
                            message: msg,
                            duration: 2000,
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentModulePage.prototype.presentAlert = function (msg, page) {
        if (page === void 0) { page = 'tabs/tabs/user-order-history'; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: msg,
                            backdropDismiss: false,
                            buttons: [{
                                    text: this.SHARED_LABELS['ok'],
                                    handler: function () {
                                        _this.navCtrl.navigateRoot([page]);
                                    }
                                }]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentModulePage.prototype.presentLoading = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: this.SHARED_LABELS['please_wait'],
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentModulePage.prototype.getStripeDetails = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.adminSettingsService.getStripeData()];
                    case 1:
                        _a.stripeData = _b.sent();
                        this.extraCharge.stripe = 'extraChargeStripe' in this.stripeData ? this.stripeData.extraChargeStripe : this.getInitObjForExtraCharge();
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentModulePage.prototype.presentStripeModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: StripePage,
                            componentProps: {
                                orderData: this.paymentData,
                                stripeData: this.stripeData,
                                autoConfirm: true
                            }
                        })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentModulePage.prototype.presentPaypalModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: PaypalPage,
                            cssClass: 'custom-modal',
                            componentProps: {
                                orderData: this.paymentData,
                                autoConfirm: true,
                                paypalObj: this.paypalObj
                            }
                        })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentModulePage.prototype.getGateways = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _i, _a, paymentGateway, paymentGatewayData;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _i = 0, _a = this.paymentGateways;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        paymentGateway = _a[_i];
                        return [4 /*yield*/, this.adminSettingsService.getPaymentGateway(paymentGateway)];
                    case 2:
                        paymentGatewayData = _b.sent();
                        if (paymentGatewayData && paymentGatewayData.active) {
                            if (paymentGateway == 'paypal') {
                                this.paypalObj = paymentGatewayData;
                                this.extraCharge.paypal = 'extraChargePaypal' in this.paypalObj ? this.paypalObj.extraChargePaypal : this.getInitObjForExtraCharge();
                            }
                            if (paymentGateway == 'cashfree') {
                                this.cashfreeObj = paymentGatewayData;
                                this.extraCharge.cashfree = 'extraChargeCashfree' in this.cashfreeObj ? this.cashfreeObj.extraChargeCashfree : this.getInitObjForExtraCharge();
                            }
                        }
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PaymentModulePage.prototype.getInitObjForExtraCharge = function () {
        return { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 };
    };
    PaymentModulePage.prototype.openUpiManualModal = function (upiManual) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var modal;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.modalController.create({
                                        component: UpiManualPaymentPage,
                                        backdropDismiss: false,
                                        componentProps: { upiManual: upiManual, pageType: 'modal' }
                                    })];
                                case 1:
                                    modal = _a.sent();
                                    modal.onDidDismiss().then(function (res) {
                                        if (res && res.data && !res.data.closed && res.data.paymentImg) {
                                            resolve({ paymentImg: res.data.paymentImg });
                                        }
                                        else {
                                            resolve({ paymentImg: '' });
                                        }
                                    });
                                    return [4 /*yield*/, modal.present()];
                                case 2:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    PaymentModulePage.prototype.openCustomOptionModal = function (customOption) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var modal;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.modalController.create({
                                        component: CustomPaymentOptionPage,
                                        backdropDismiss: false,
                                        componentProps: { customOption: customOption, pageType: 'modal' }
                                    })];
                                case 1:
                                    modal = _a.sent();
                                    modal.onDidDismiss().then(function (res) {
                                        if (res && res.data && !res.data.closed && res.data.response) {
                                            resolve({ response: res.data.response });
                                        }
                                        else {
                                            resolve({ response: null });
                                        }
                                    });
                                    return [4 /*yield*/, modal.present()];
                                case 2:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    PaymentModulePage.prototype.closeModal = function () {
        this.modalController.dismiss();
        if (this.paymentData.successRoute) {
            this.navCtrl.navigateRoot([this.paymentData.successRoute]);
        }
    };
    PaymentModulePage.prototype.removeSubscription = function () {
        this.events.unsubscribe('user:setPaymentModeOfOrderByUserSuccessfully');
        this.events.unsubscribe('admin-settings:publishPaytmData');
        this.events.unsubscribe('admin-settings:publishRazorPayData');
        this.events.unsubscribe('admin-settings:publishPaymentInfoData');
        this.events.unsubscribe('order:ac_modeSetToCashSuccess');
        this.events.unsubscribe('wallet:publishUserWalletDetails');
        this.events.unsubscribe('wallet:publishWalletSettings');
        this.events.unsubscribe('order:ac_completePaymentWithWalletSuccess');
    };
    return PaymentModulePage;
}());
export { PaymentModulePage };
