import * as tslib_1 from "tslib";
import { AngularFirestore } from "@angular/fire/firestore";
import { first } from "rxjs/operators";
import { Storage } from "@ionic/storage";
import { ConfigService } from "../config/config.service";
import { HttpClient } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@ionic/storage";
import * as i3 from "../config/config.service";
import * as i4 from "@angular/common/http";
var MultiCountriesService = /** @class */ (function () {
    function MultiCountriesService(afs, storage, configService, http) {
        this.afs = afs;
        this.storage = storage;
        this.configService = configService;
        this.http = http;
        this.multiCountriesSettings = {
            countries: [
                {
                    countryName: "India",
                    countryCode: "in",
                    currencyCode: "INR",
                    dialCode: "+91",
                    active: true,
                },
            ],
            settings: {
                autoExchange: false,
                defaultCountry: {
                    countryCode: "in",
                    countryName: "India",
                    currencyCode: "INR",
                    dialCode: "+91",
                    active: true,
                },
            },
        };
        this.countryInLogin = {};
        this.rates = [];
    }
    MultiCountriesService.prototype.getMultiCountries = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var multiCountriesSettings, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs
                                .collection("features")
                                .doc("multiCountries")
                                .valueChanges()
                                .pipe(first())
                                .toPromise()];
                    case 1:
                        multiCountriesSettings = _a.sent();
                        this.multiCountriesSettings = multiCountriesSettings || this.multiCountriesSettings;
                        this.multiCountriesSettings.countries = this.multiCountriesSettings.countries.filter(function (country) { return country.active === true; });
                        this.getDefaultCountry();
                        this.getExchangeRates();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.log(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MultiCountriesService.prototype.setDefaultCountry = function (country) {
        if (country === void 0) { country = this.countryInLogin; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.set("defaultCountry", country)];
                    case 1:
                        _a.sent();
                        this.setConfig(country);
                        return [2 /*return*/];
                }
            });
        });
    };
    MultiCountriesService.prototype.getDefaultCountry = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var defaultCountry;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get("defaultCountry")];
                    case 1:
                        defaultCountry = _a.sent();
                        console.log('defaultCountry....', defaultCountry);
                        if (defaultCountry && Object.keys(defaultCountry).length) {
                            this.setConfig(defaultCountry);
                        }
                        else {
                            this.setDefaultCountry(this.multiCountriesSettings.settings.defaultCountry);
                        }
                        return [2 /*return*/, defaultCountry || this.multiCountriesSettings.settings.defaultCountry];
                }
            });
        });
    };
    MultiCountriesService.prototype.setConfig = function (country) {
        this.configService.environment.defaultCountryCode = country.dialCode;
        this.configService.environment.currencyCode = country.currencyCode;
    };
    MultiCountriesService.prototype.getExchangeRates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.http.get('https://api.exchangerate-api.com/v4/latest/INR').subscribe(function (response) {
                    console.log('response', response);
                    _this.rates = response.rates;
                });
                return [2 /*return*/];
            });
        });
    };
    MultiCountriesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MultiCountriesService_Factory() { return new MultiCountriesService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.Storage), i0.ɵɵinject(i3.ConfigService), i0.ɵɵinject(i4.HttpClient)); }, token: MultiCountriesService, providedIn: "root" });
    return MultiCountriesService;
}());
export { MultiCountriesService };
