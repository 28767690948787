import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { AngularFireStorage } from '@angular/fire/storage';
import { map, first } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import * as firebase from 'firebase';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@ionic/angular";
import * as i3 from "@angular/fire/storage";
import * as i4 from "@ionic/storage";
var DeliveryService = /** @class */ (function () {
    function DeliveryService(afs, events, fbStorage, storage) {
        this.afs = afs;
        this.events = events;
        this.fbStorage = fbStorage;
        this.storage = storage;
    }
    DeliveryService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('delivery:getAllOrdersOfDeliveryAgent', function (uid) {
            _this.getAllOrdersOfDeliveryAgent(uid);
        });
        this.events.subscribe('delivery:updateLatLongOfDeliveryAgent', function (lat, lng) {
            _this.updateLatLongOfDeliveryAgent(lat, lng);
        });
        this.events.subscribe('delivery:updateDeliveryStatus', function (oid, status) {
            _this.updateDeliveryStatus(oid, status);
        });
        this.events.subscribe('delivery:getLatestLatLngOfDeliveryAgent', function (agentId) {
            _this.getLatestLatLngOfDeliveryAgent(agentId);
        });
        this.events.subscribe('delivery:startDelivery', function (orderId, address) {
            _this.startDelivery(orderId, address);
        });
    };
    DeliveryService.prototype.startDelivery = function (orderId, address) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var getLatLng;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    if (!address.lat) {
                        getLatLng = firebase.functions().httpsCallable('location-getLatLng');
                        getLatLng({
                            deliveryAddress: address.address,
                            orderId: orderId
                        }).then(function (res) {
                            //// console.log('response form server...', res);
                            var status = res.data.status;
                            if (status === 'OK') {
                                _this.updateDeliveryStatus(orderId, 'inProgress', res.data.results[0].geometry.location);
                                _this.events.publish('delivery:startDeliverySuccess', orderId, res.data.results[0].geometry.location);
                            }
                            else if (status === 'ZERO_RESULTS') {
                                _this.events.publish('delivery:startDeliveryUnsuccessful', "Delivery Address is not existed!");
                            }
                            else {
                                _this.events.publish('delivery:startDeliveryUnsuccessful', "There is some problem in fetching address location. Please try again later.");
                            }
                        }).catch(function (error) {
                            //// console.log(error);
                        });
                    }
                    else {
                        this.updateDeliveryStatus(orderId, 'inProgress', { lat: address.lat, lng: address.lng });
                        this.events.publish('delivery:startDeliverySuccess', orderId, { lat: address.lat, lng: address.lng });
                    }
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    DeliveryService.prototype.getAllOrdersOfDeliveryAgent = function (uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderRef, orderData, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        orderRef = this.afs.collection('orders', function (ref) { return ref
                            .orderBy('createdAt', 'desc')
                            .where('deliveryAgentId', '==', uid); });
                        return [4 /*yield*/, orderRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); }))];
                    case 1:
                        orderData = _a.sent();
                        orderData.subscribe(function (orders) {
                            if (!orders.length) {
                                _this.events.publish('delivery:noOrdersOfDeliveryAgent');
                                return false;
                            }
                            else {
                                //// console.log('getAllOrdersOfDeliveryAgent', orders);
                                _this.events.publish('delivery:publishAllOrdersOfDeliveryAgent', orders);
                            }
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DeliveryService.prototype.updateDeliveryStatus = function (oid, status, location) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderRef, orderData, deliverStartedMsg, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        orderRef = this.afs.collection('orders', function (ref) { return ref.where('orderId', '==', oid); });
                        return [4 /*yield*/, orderRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        orderData = _a.sent();
                        if (!(status === 'inProgress')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderData[0].id).update({ deliveryStatus: status, status: 'Dispatched' })];
                    case 2:
                        _a.sent();
                        deliverStartedMsg = {
                            author: 'admin',
                            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                            isRead: true,
                            orderId: orderData[0].orderId,
                            published: true,
                            status: 'deliveryStarted',
                            type: 'order',
                            agentId: orderData[0].deliveryAgentId,
                            deliveryLatLng: location
                        };
                        this.events.publish('chat:sendMsg', deliverStartedMsg, orderData[0].userId);
                        _a.label = 3;
                    case 3:
                        if (!(status === 'delivered')) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderData[0].id).update({ deliveryStatus: status, status: 'Delivered' })];
                    case 4:
                        _a.sent();
                        this.events.publish('delivery:finishedNavigationSuccess');
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    DeliveryService.prototype.updateLatLongOfDeliveryAgent = function (lat, lng) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.storage.get('uid').then(function (uid) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var error_3;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.afs.collection('users').doc(uid).update({
                                        latitude: lat,
                                        longitude: lng
                                    })];
                            case 1:
                                _a.sent();
                                return [3 /*break*/, 3];
                            case 2:
                                error_3 = _a.sent();
                                console.dir(error_3);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    DeliveryService.prototype.getLatestLatLngOfDeliveryAgent = function (agentId) {
        var _this = this;
        try {
            var agentRef = this.afs.collection('users').doc(agentId);
            var agentSnap = agentRef.valueChanges();
            agentSnap.subscribe(function (res) {
                //// console.log('res', res);
                _this.events.publish('delivery:publishLatestLatLngOfDeliveryAgent', res.latitude, res.longitude);
            });
        }
        catch (error) {
            console.dir(error);
        }
    };
    DeliveryService.prototype.getLatLngFromAddress = function (address) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var latLongObj;
                        return tslib_1.__generator(this, function (_a) {
                            latLongObj = firebase.functions().httpsCallable('location-getLatLngFromAddress');
                            latLongObj(address).then(function (res) {
                                resolve(res.data);
                            });
                            return [2 /*return*/];
                        });
                    }); })];
            });
        });
    };
    DeliveryService.prototype.getAddressFromLatLng = function (latLngObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var Address;
                        return tslib_1.__generator(this, function (_a) {
                            Address = firebase.functions().httpsCallable('location-getAddressFromLatLng');
                            Address(latLngObj).then(function (res) {
                                resolve(res.data);
                            });
                            return [2 /*return*/];
                        });
                    }); })];
            });
        });
    };
    DeliveryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DeliveryService_Factory() { return new DeliveryService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.Events), i0.ɵɵinject(i3.AngularFireStorage), i0.ɵɵinject(i4.Storage)); }, token: DeliveryService, providedIn: "root" });
    return DeliveryService;
}());
export { DeliveryService };
