import * as tslib_1 from "tslib";
import { Storage } from '@ionic/storage';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, Events, ToastController, AlertController, LoadingController } from '@ionic/angular';
import { ConfigService } from 'src/app/services/config/config.service';
import { LabelService } from 'src/app/services/label/label.service';
import * as moment from 'moment';
import { CartService } from 'src/app/services/cart/cart.service';
var CreateSubscriptionPage = /** @class */ (function () {
    function CreateSubscriptionPage(labelService, modalController, configService, events, toastController, router, alertController, storage, loadingController, cartService) {
        this.labelService = labelService;
        this.modalController = modalController;
        this.configService = configService;
        this.events = events;
        this.toastController = toastController;
        this.router = router;
        this.alertController = alertController;
        this.storage = storage;
        this.loadingController = loadingController;
        this.cartService = cartService;
        this.headerText = '';
        this.subData = {
            qtyPerDay: 1,
            product: null,
            type: 'daily',
            active: true,
            deliverySlot: {},
            deliveryDays: [],
            deliveryDates: [],
            totalDeliveries: 0,
            totalWeeks: 0,
            totalMonths: 0,
            discount: 0,
            totalMrp: 0,
            offerDiscount: 0,
            amountPayable: 0
        };
        this.days = [];
        this.dates = [{ date: '01', active: false },
            { date: '02', active: false },
            { date: '03', active: false },
            { date: '04', active: false },
            { date: '05', active: false },
            { date: '06', active: false },
            { date: '07', active: false },
            { date: '08', active: false },
            { date: '09', active: false },
            { date: '10', active: false },
            { date: '11', active: false },
            { date: '12', active: false },
            { date: '13', active: false },
            { date: '14', active: false },
            { date: '15', active: false },
            { date: '16', active: false },
            { date: '17', active: false },
            { date: '18', active: false },
            { date: '19', active: false },
            { date: '20', active: false },
            { date: '21', active: false },
            { date: '22', active: false },
            { date: '23', active: false },
            { date: '24', active: false },
            { date: '25', active: false },
            { date: '26', active: false },
            { date: '27', active: false },
            { date: '28', active: false },
            { date: '29', active: false },
            { date: '30', active: false },
            { date: '31', active: false }];
        this.timeSchedules = [];
        this.deliveryTime = null;
    }
    CreateSubscriptionPage.prototype.ngOnInit = function () {
        var _this = this;
        this.currencyCode = this.configService.environment.currencyCode;
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.CREATE_SUBSCRIPTION_LABELS = this.labelService.labels['CREATE_SUBSCRIPTION'];
        this.headerText = this.CREATE_SUBSCRIPTION_LABELS['header_text'];
        console.log('product', this.product);
        console.log('subSettings', this.subSettings);
        this.totalDeliveries = this.subSettings.dailyDeliveries.min;
        this.subData.discount = this.product.data.subscription.dailyDiscount ? this.product.data.subscription.dailyDiscount : 0;
        setTimeout(function () {
            _this.product.data.discountedPrice = _this.product.data.prodPrice - (_this.product.data.prodPrice * (_this.subData.discount / 100));
            _this.product.data.prodName = !_this.product.data.isPriceList ? _this.product.data.prodName : _this.product.data.prodName + " (" + _this.product.data.variantValue + ")";
            _this.product.data.prodDesc = !_this.product.data.isPriceList ? '' : _this.product.data.variantValue;
        }, 100);
    };
    CreateSubscriptionPage.prototype.ionViewWillEnter = function () {
        this.initialiseSubscriptions();
        this.events.publish('delivery-settings:getDeliverySettingsData');
    };
    CreateSubscriptionPage.prototype.ionViewWillLeave = function () {
        this.removeSubscriptions();
    };
    CreateSubscriptionPage.prototype.initialiseSubscriptions = function () {
        var _this = this;
        this.events.subscribe('delivery-settings:publishDeliverySettingsData', function (data) {
            if (!_this.isEmptyObj(data)) {
                if (data.deliveryDays.length > 0) {
                    for (var _i = 0, _a = data.deliveryDays; _i < _a.length; _i++) {
                        var day = _a[_i];
                        _this.days.push({ day: day, active: true });
                    }
                }
                else {
                    var days = [
                        { day: 'Sunday', active: true },
                        { day: 'Monday', active: true },
                        { day: 'Tuesday', active: true },
                        { day: 'Wednesday', active: true },
                        { day: 'Thursday', active: true },
                        { day: 'Friday', active: true },
                        { day: 'Saturday', active: true }
                    ];
                    for (var _b = 0, days_1 = days; _b < days_1.length; _b++) {
                        var day = days_1[_b];
                        _this.days.push(day);
                    }
                }
                if (data.timeSchedules.length > 0) {
                    _this.timeSchedules = data.timeSchedules;
                }
            }
        });
    };
    CreateSubscriptionPage.prototype.isEmptyObj = function (object) {
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    };
    CreateSubscriptionPage.prototype.decrementQuantity = function () {
        if (this.subData.qtyPerDay > 1) {
            this.subData.qtyPerDay -= 1;
        }
    };
    CreateSubscriptionPage.prototype.incrementQuantity = function () {
        this.subData.qtyPerDay += 1;
    };
    CreateSubscriptionPage.prototype.setSubType = function (type) {
        if (type === 'daily') {
            this.totalDeliveries = this.subSettings.dailyDeliveries.min;
            this.subData.discount = this.product.data.subscription.dailyDiscount ? this.product.data.subscription.dailyDiscount : 0;
            this.product.data.discountedPrice = this.product.data.prodPrice - (this.product.data.prodPrice * (this.subData.discount / 100));
        }
        else if (type === 'weekly') {
            this.totalDeliveries = this.subSettings.weeklyDeliveries.min;
            this.subData.discount = this.product.data.subscription.weeklyDiscount ? this.product.data.subscription.weeklyDiscount : 0;
            this.product.data.discountedPrice = this.product.data.prodPrice - (this.product.data.prodPrice * (this.subData.discount / 100));
        }
        else {
            this.totalDeliveries = this.subSettings.monthlyDeliveries.min;
            this.subData.discount = this.product.data.subscription.monthlyDiscount ? this.product.data.subscription.monthlyDiscount : 0;
            this.product.data.discountedPrice = this.product.data.prodPrice - (this.product.data.prodPrice * (this.subData.discount / 100));
        }
        this.subData.type = type;
    };
    CreateSubscriptionPage.prototype.daySelectToggle = function (i) {
        this.days[i].active = !this.days[i].active;
    };
    CreateSubscriptionPage.prototype.dateSelectToggle = function (i) {
        this.dates[i].active = !this.dates[i].active;
        console.log('this.dates', this.dates);
    };
    CreateSubscriptionPage.prototype.decrementDeliveries = function () {
        var minDeliveries;
        if (this.subData.type === 'daily') {
            minDeliveries = this.subSettings.dailyDeliveries.min;
        }
        else if (this.subData.type === 'weekly') {
            minDeliveries = this.subSettings.weeklyDeliveries.min;
        }
        else {
            minDeliveries = this.subSettings.monthlyDeliveries.min;
        }
        if (this.totalDeliveries > minDeliveries) {
            this.totalDeliveries -= 1;
        }
        if (this.totalDeliveries === minDeliveries) {
            this.presentToast(this.CREATE_SUBSCRIPTION_LABELS['min_value_check'] + " " + minDeliveries);
        }
    };
    CreateSubscriptionPage.prototype.incrementDeliveries = function () {
        var maxDeliveries;
        if (this.subData.type === 'daily') {
            maxDeliveries = this.subSettings.dailyDeliveries.max;
        }
        else if (this.subData.type === 'weekly') {
            maxDeliveries = this.subSettings.weeklyDeliveries.max;
        }
        else {
            maxDeliveries = this.subSettings.monthlyDeliveries.max;
        }
        if (this.totalDeliveries < maxDeliveries) {
            this.totalDeliveries += 1;
        }
        if (this.totalDeliveries === maxDeliveries) {
            this.presentToast(this.CREATE_SUBSCRIPTION_LABELS['max_value_check'] + " " + maxDeliveries);
        }
    };
    CreateSubscriptionPage.prototype.deliveriesInput = function () {
        var maxDeliveries;
        if (this.subData.type === 'daily') {
            maxDeliveries = this.subSettings.dailyDeliveries.max;
        }
        else if (this.subData.type === 'weekly') {
            maxDeliveries = this.subSettings.weeklyDeliveries.max;
        }
        else {
            maxDeliveries = this.subSettings.monthlyDeliveries.max;
        }
        var minDeliveries;
        if (this.subData.type === 'daily') {
            minDeliveries = this.subSettings.dailyDeliveries.min;
        }
        else if (this.subData.type === 'weekly') {
            minDeliveries = this.subSettings.weeklyDeliveries.min;
        }
        else {
            minDeliveries = this.subSettings.monthlyDeliveries.min;
        }
        if (this.totalDeliveries > maxDeliveries) {
            this.totalDeliveries = minDeliveries;
            this.presentToast(this.CREATE_SUBSCRIPTION_LABELS['please_enter_value_less_than'] + " " + maxDeliveries);
        }
    };
    CreateSubscriptionPage.prototype.selectTime = function (e) {
        console.log(e.target.value);
        this.deliveryTime = e.target.value;
    };
    CreateSubscriptionPage.prototype.subscribe = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var deliveryDays, deliveryDates, product, cartObj, subMsg;
            return tslib_1.__generator(this, function (_a) {
                deliveryDays = [];
                deliveryDates = [];
                this.subData.product = tslib_1.__assign({ productId: this.product.id }, this.product.data);
                if (this.product.hasOwnProperty('parentProductId')) {
                    this.subData.product['parentProductId'] = this.product.parentProductId;
                }
                this.subData.deliverySlot = this.deliveryTime ? this.deliveryTime : {};
                if (this.subData.type === 'daily') {
                    this.subData.totalDeliveries = this.totalDeliveries * this.subData.qtyPerDay;
                    this.subData.deliveryDays = [];
                    this.subData.deliveryDates = [];
                }
                else if (this.subData.type === 'weekly') {
                    this.days.forEach(function (d) {
                        if (d.active) {
                            deliveryDays.push(d.day);
                        }
                    });
                    if (!deliveryDays.length) {
                        this.presentAlert(this.CREATE_SUBSCRIPTION_LABELS['select_some_days_of_week']);
                    }
                    else {
                        this.subData.deliveryDays = deliveryDays;
                        this.subData.totalWeeks = this.totalDeliveries;
                        this.subData.totalDeliveries = this.totalDeliveries * this.subData.deliveryDays.length * this.subData.qtyPerDay;
                        this.subData.deliveryDates = [];
                        this.subData.totalMonths = 0;
                    }
                }
                else {
                    this.dates.forEach(function (d) {
                        if (d.active) {
                            deliveryDates.push(d.date);
                        }
                    });
                    if (!deliveryDates.length) {
                        this.presentAlert(this.CREATE_SUBSCRIPTION_LABELS['select_any_date_of_month']);
                    }
                    else {
                        this.subData.deliveryDates = deliveryDates;
                        this.subData.totalMonths = this.totalDeliveries;
                        this.subData.totalDeliveries = this.totalDeliveries * this.subData.deliveryDates.length * this.subData.qtyPerDay;
                        this.subData.deliveryDays = [];
                        this.subData.totalWeeks = 0;
                    }
                }
                this.subData.totalMrp = this.subData.totalDeliveries * this.product.data.prodPrice;
                this.subData.amountPayable = this.subData.totalDeliveries * this.product.data.discountedPrice;
                this.subData.offerDiscount = this.subData.totalMrp - this.subData.amountPayable;
                console.log('subData', this.subData);
                if ((this.subData.type === 'weekly' && deliveryDays.length) || (this.subData.type === 'monthly' && deliveryDates.length) || this.subData.type === 'daily') {
                    product = this.product.data;
                    this.subData['isCashAllowed'] = this.subSettings.isCashAllowed;
                    cartObj = {
                        orderType: 'subscription',
                        name: product.prodName,
                        description: product.prodDesc,
                        quantity: this.subData.totalDeliveries,
                        img: product.coverPic,
                        commentMsg: '',
                        commentImgs: [],
                        maxQty: null,
                        minQty: null,
                        gst: product.gst ? product.gst : 0,
                        status: typeof product.status !== 'undefined' ? product.status : true,
                        stopWhenNoQty: product.hasOwnProperty('stopWhenNoQty') && typeof product.stopWhenNoQty !== 'undefined' ? product.stopWhenNoQty : false,
                        totalQty: product.productQty ? product.productQty : '',
                        hsn: product.hsnCode ? product.hsnCode : '',
                        sku: product.productCode ? product.productCode : '',
                        barcode: product.barcode ? product.barcode : '',
                        subData: this.subData,
                        shippingWt: product.shippingWeight || 0,
                        barcodeNo: product.barcodeNo || '',
                        gstExclusive: product.gstExclusive || false,
                        extraCharges: ('extraCharges' in product) && (typeof product.extraCharges === 'object') && product.extraCharges.active ? product.extraCharges : { charge: 0 },
                        isCod: 'isCod' in product ? product.isCod : true,
                        vendorId: product.vendorId || ''
                    };
                    if (product.discountedPrice && (product.discountedPrice !== product.prodPrice)) {
                        cartObj['mrpPrice'] = product.prodPrice;
                        cartObj['price'] = product.discountedPrice;
                    }
                    else {
                        cartObj['price'] = product.prodPrice;
                    }
                    if (product.hasOwnProperty('color') && product.color.hasOwnProperty('name')) {
                        cartObj['color'] = product.color;
                    }
                    if (product.hasOwnProperty('parentProductId')) {
                        cartObj['parentProductId'] = product.parentProductId;
                        cartObj['productId'] = this.product.id;
                    }
                    else {
                        cartObj['productId'] = this.product.id;
                    }
                    subMsg = '';
                    console.log(moment().isBefore(moment({ hour: 20, minute: 0 })));
                    if (moment().isBefore(moment({ hour: 20, minute: 0 }))) {
                        subMsg = this.CREATE_SUBSCRIPTION_LABELS['subscription_active_msg_before_8'];
                    }
                    else {
                        subMsg = this.CREATE_SUBSCRIPTION_LABELS['subscription_active_msg_after_8'];
                    }
                    this.subscriptionStartAlert(cartObj, subMsg);
                }
                return [2 /*return*/];
            });
        });
    };
    CreateSubscriptionPage.prototype.getFutureDates = function (dates) {
        var now = Date.now();
        var futureDates = dates.filter(function (date) {
            return date && (new Date(date)).getTime() > now;
        });
        return futureDates;
    };
    CreateSubscriptionPage.prototype.getDiscountedPrice = function () {
        var discountedPrice = this.product.data.prodPrice - (this.product.data.prodPrice * (this.subData.discount / 100));
        return discountedPrice;
    };
    CreateSubscriptionPage.prototype.presentAlert = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: msg,
                            buttons: ["" + this.SHARED_LABELS['ok']]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateSubscriptionPage.prototype.subscriptionStartAlert = function (cartObj, subMsg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: subMsg,
                            buttons: [{
                                    text: this.CREATE_SUBSCRIPTION_LABELS['continue'],
                                    handler: function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                        return tslib_1.__generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, this.presentLoading()];
                                                case 1:
                                                    _a.sent();
                                                    return [4 /*yield*/, this.cartService.addProductToCart(cartObj)];
                                                case 2:
                                                    _a.sent();
                                                    this.loading.dismiss();
                                                    this.closeModal();
                                                    this.router.navigate(['user-cart']);
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); }
                                }]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateSubscriptionPage.prototype.presentToast = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastController.create({
                            message: msg,
                            duration: 2000
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateSubscriptionPage.prototype.closeModal = function () {
        this.modalController.dismiss();
    };
    CreateSubscriptionPage.prototype.presentLoading = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: this.CREATE_SUBSCRIPTION_LABELS['creating_subscription_loading_msg'],
                                duration: 10000,
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateSubscriptionPage.prototype.onClickQtyPerDay = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            subHeader: this.CREATE_SUBSCRIPTION_LABELS['enter_qty'],
                            inputs: [
                                {
                                    name: 'qty',
                                    type: 'number',
                                    placeholder: this.CREATE_SUBSCRIPTION_LABELS['enter_qty_here']
                                }
                            ],
                            buttons: [
                                {
                                    text: this.SHARED_LABELS['cancel'],
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: function () {
                                        console.log('Confirm Cancel');
                                    }
                                }, {
                                    text: this.SHARED_LABELS['done'],
                                    handler: function (data) {
                                        if (!parseInt(data.qty)) {
                                            _this.presentToast(_this.CREATE_SUBSCRIPTION_LABELS['enter_valid_qty']);
                                            return false;
                                        }
                                        else {
                                            _this.subData.qtyPerDay = parseInt(data.qty);
                                        }
                                    }
                                }
                            ]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateSubscriptionPage.prototype.onClickTotalDeliveries = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            subHeader: this.CREATE_SUBSCRIPTION_LABELS['enter_value'],
                            inputs: [
                                {
                                    name: 'value',
                                    type: 'number',
                                    placeholder: this.CREATE_SUBSCRIPTION_LABELS['enter_value_here']
                                }
                            ],
                            buttons: [
                                {
                                    text: this.SHARED_LABELS['cancel'],
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: function () {
                                        console.log('Confirm Cancel');
                                    }
                                }, {
                                    text: this.SHARED_LABELS['done'],
                                    handler: function (data) {
                                        if (!parseInt(data.value)) {
                                            _this.presentToast(_this.CREATE_SUBSCRIPTION_LABELS['enter_valid_value']);
                                            return false;
                                        }
                                        else {
                                            var maxDeliveries = void 0;
                                            if (_this.subData.type === 'daily') {
                                                maxDeliveries = _this.subSettings.dailyDeliveries.max;
                                            }
                                            else if (_this.subData.type === 'weekly') {
                                                maxDeliveries = _this.subSettings.weeklyDeliveries.max;
                                            }
                                            else {
                                                maxDeliveries = _this.subSettings.monthlyDeliveries.max;
                                            }
                                            var minDeliveries = void 0;
                                            if (_this.subData.type === 'daily') {
                                                minDeliveries = _this.subSettings.dailyDeliveries.min;
                                            }
                                            else if (_this.subData.type === 'weekly') {
                                                minDeliveries = _this.subSettings.weeklyDeliveries.min;
                                            }
                                            else {
                                                minDeliveries = _this.subSettings.monthlyDeliveries.min;
                                            }
                                            if (parseInt(data.value) > maxDeliveries) {
                                                _this.totalDeliveries = minDeliveries;
                                                _this.presentToast(_this.CREATE_SUBSCRIPTION_LABELS['max_value_check'] + " " + maxDeliveries);
                                            }
                                            else if (parseInt(data.value) < minDeliveries) {
                                                _this.totalDeliveries = minDeliveries;
                                                _this.presentToast(_this.CREATE_SUBSCRIPTION_LABELS['min_value_check'] + " " + minDeliveries);
                                            }
                                            else {
                                                _this.totalDeliveries = parseInt(data.value);
                                            }
                                        }
                                    }
                                }
                            ]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateSubscriptionPage.prototype.removeSubscriptions = function () {
        this.events.unsubscribe('delivery-settings:publishDeliverySettingsData');
    };
    return CreateSubscriptionPage;
}());
export { CreateSubscriptionPage };
